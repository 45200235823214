import axios from 'axios';
import { Constants } from '../components/Constants';
import _ from 'lodash';

export const isBlank = (value) => (_.isString(value) && _.isEmpty(value)) || (_.isArray(value) && value.length === 0) || (!_.isDate(value) && _.isObject(value) && Object.keys(value).length === 0) || _.isNil(value) || _.isNull(value) || _.isUndefined(value);

export const log = (...message) => (!Constants.PRODUCTION ? console.log(message.map((_message) => (_.isObject(_message) ? JSON.stringify(_.compact(_message)) : _message)).join(' ')) : null);

export const sleep = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getGeolocation = async () => {
	const geo_options = {
			maximumAge: 5 * 60 * 1000,
			timeout: 5000,
		},
		supportsGeo = 'geolocation' in navigator,
		geoPermissionGranted = (position) => {
			geolocation = {
				latitude: position.coords.latitude,
				longitude: position.coords.longitude,
			};
		},
		geoPermissionDenied = (error) => {
			console.log('geolocation permission denied', error);
			geolocation = {
				// default is London, UK
				longitude: -0.118092,
				latitude: 51.509865,
			};
		};

	let geolocation;

	if (supportsGeo) {
		geolocation = navigator.geolocation.getCurrentPosition(geoPermissionGranted, geoPermissionDenied, geo_options);

		while (!geolocation) {
			await new Promise((resolve) => setTimeout(resolve, 100));
		}
	} else {
		console.log('device does not support geolocation');
		geolocation = {
			// default is London, UK
			longitude: -0.118092,
			latitude: 51.509865,
		};
	}

	return geolocation;
};

export const fetch = async (data) => {
	const instanceOptions = {
			baseURL: data.baseURL ? data.baseURL : Constants.LGV_API,
			timeout: 60000,
			headers: { 'Content-Type': 'application/json', 
				Authorization: !_.includes(['authenticate'], data.url) ? `Bearer ${localStorage.getItem('tokens')}` : ''
			},
			method: 'POST',
			responseType: 'json',
			...data, // can override the above
		},
		instance = axios.create(instanceOptions),
		response = await instance({
			url: data.baseURL ? data.baseURL : `${Constants.LGV_API}/${data.url}`,
			data: instanceOptions.method === 'POST' ? JSON.stringify({ usersId: localStorage.getItem('usersId'), ...(data.data || {}) }) : undefined,
		})
			.then((_response) => {
				let response = _response.data;

				if (data.responseType === 'arraybuffer') {
					response = `data:image/webp;base64,${Buffer.from(response, 'binary').toString('base64')}`;
				}

				return response;
			})
			.catch((err) => {
				console.error('Services.fetch:: API server error with request for ', data.url, err.message);
				return { Error: err.message };
			});

	return response;
};
