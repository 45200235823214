/**
 * Description of LakeGuardView.js
 *
 * This module contains the main operating code of LakeGuard View
 *
 * @author github.com/doncarlosone
 * @copyright 2023 BlueGreen Water Technologies
 */

import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { fetch, isBlank, log, sleep, getGeolocation } from '../components/Services';
import { isDate, add as addDays, sub as subDays, format as formatDate, startOfMonth, endOfMonth, parse as parseDate, endOfYear, format, getMonth, getYear, parseISO } from 'date-fns';

// MapBox
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '../css/mapbox-gl-geocoder.css';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { square, buffer as mb_buffer, point, bbox, area, bboxPolygon /*centroid*/ } from '@turf/turf';
import chroma from 'chroma-js';

import '../css/LakeGuardView.css';
// import LGVContext from '../context/lgv';// delete this file
// import { Navigator } from '../components/Navigator';

// import { LocationEditor } from '../components/LocationEditor';
import HUDControl from '../components/HUDControl';

// import AOILibraryControl from '../mapboxControls/AOILibraryControl';

import View360Control from '../mapboxControls/View360Control';
import CoordinatesControl from '../mapboxControls/CoordinatesControl';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import LZString from 'lz-string';

import {
	AOI_LOAD_TIME_EXCEED,
	AOI_LOAD_TIME_VALID,
	AOI_MAXIMUM_LOAD_TIME,
	BLOOM,
	CLOUDS,
	Constants,
	ERROR_MODAL,
	EXPORT_MODAL,
	INTERFERENCES,
	PLANET_BGI,
	PLANET_BLOOM,
	PLANET_CLOUDS,
	PLANET_TRUE_COLOR,
	PRO_USER_DEFAULT_LAYERS,
	SATELLITES,
	STANDARD_USER_DEFAULT_LAYERS,
	TRUECOLOR,
	getLegends,
} from '../components/Constants';
import { Credentials } from '../components/Credentials';

import CheapRuler from 'cheap-ruler';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { ProSidebarProvider /*, Sidebar, Menu, MenuItem, SubMenu*/ } from 'react-pro-sidebar';
import { isAuthorized } from '../services/common';
import TopBar from '../components/topBar/TopBar';
import LegendComponent from '../components/legendComponent/LegendComponent';
import MainModal from '../components/modal/Modal';
import MainTrends from '../components/trends/MainTrends';
import LayerManagerComponent from '../components/layerManager/LayerManagerComponent';
import { backToCurrentLocation, goToLocation, showLayer, showPlanetService } from '../services/mapboxServices';
import dayjs from 'dayjs';
import FilterCenterFocusIcon from '@mui/icons-material/FilterCenterFocus';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import ConfirmModal from '../shared/UI/components/materialConfirmModal/Index';
import ReadMoreIcon from '@mui/icons-material/ReadMore';

import MaterialNotification from '../shared/UI/components/materialNotification/MaterialNotification';
import LocationModal from '../components/locationModal/LocationModal';
import { getTrendsDataFromImageries, getWindDirection } from '../services/trendsServices';

// import { WSService } from '../services/WSService';

//import { hexGrid } from '@turf/turf';

// eslint-disable-next-line no-restricted-globals
// if (
//   !window?.location.href.includes("localhost") &&
//   !window?.location.href.includes("charles") &&
//   !window?.location.href.includes("majde")
// ) {
//   // eslint-disable-next-line import/no-webpack-loader-syntax
//   mapboxgl.workerClass =
//     require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
// }

function LakeGuardView() {
	// const [showNewUI, setShowNewUi] = useState(true);
	const [appLoaded, setAppLoaded] = useState(false);

	const userAgent = navigator?.userAgent?.toLowerCase();
	const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
	// const isTablet = true;
	useEffect(() => {
		if (!appLoaded) {
			setAppLoaded(true);
		}
	});

	return <>{appLoaded && <LgvWrapper isTablet={isTablet} />}</>;
}

function LgvWrapper(props) {
	const { isTablet } = props;
	const [locationSelectedDate, setLocationSelectedDate] = useState('');
	const [confirmModalState, setConfirmModalState] = useState(null);
	const [showNewTrends, setShowNewTrends] = useState('');
	const [isPortrait, setIsPortrait] = useState(false);
	const [collapseTrends, setCollapseTrends] = useState(false);
	const [modalOpened, setModalOpened] = useState(false);
	const [openAoiList, setOpenAoiList] = useState(false);

	let showCreateLocationRef = useRef(false);
	const setLocationName = async (name) => {
		let state = appState;
		state.location.name = _.startCase(name);
		state.location.description = state.location.description || state.location.name;
		await updateState(state, 'setLocationName');
	};

	const [closeLocationPopupClicked, setcloseLocationPopupClicked] = useState(false);
	const [satelliteView, setSatelliteView] = useState(SATELLITES.copernicus);

	const [showDefaultLayers, setShowDefaultLayers] = useState(false);
	const [loading, setLoading] = useState(false);

	const [showNotification, setShowNotification] = useState({
		show: false,
		msg: '',
		severity: '',
	});

	let isProUser = false;
	let defaultLayers = isProUser ? PRO_USER_DEFAULT_LAYERS : STANDARD_USER_DEFAULT_LAYERS;
	const [selectedLayers, setSelectedLayers] = useState(defaultLayers);

	const showNavigator = useRef(false);

	const setAnalysis = async (event, value) => {
		let element = event && event.target && event.target.classList && event.target.classList.length > 0 ? event.target : event && event.target && event.target.parentElement ? event.target.parentElement : event && event.parentElement ? event.parentElement : undefined;

		let newState = { ...appState };

		if (element && element?.classList?.length > 0 && element?.attributes?.length > 0) {
			newState.analysis = value;

			let selected = appState.doc.querySelector('.imageryTypeSelected'),
				itemSelected = appState.doc.querySelector('.imageryBlock .selected'),
				dateSelected = itemSelected ? itemSelected.id.split('_')[1] : undefined;

			if (selected) {
				selected.classList.remove('imageryTypeSelected');

				if (itemSelected) {
					// item selected = imagery block
					itemSelected.classList.remove('selected');
				}
			}

			element.classList.add('imageryTypeSelected');

			if (dateSelected) {
				itemSelected = appState.doc.querySelector(`.imageryBlock_${dateSelected} .imageButton`);
				itemSelected.classList.add('selected');
			}
			await updateState(newState, 'setAnalysis');

			return newState;
		}
	};

	const deleteLocation = async () => {
		let state = appState;

		if (state.userIsAdmin) {
			const deleteThisLocation = async () => {
				await fetch({
					url: `deleteLocation`,
					data: { locationsId: state.location.locationsId },
				}).then(async (location) => {
					if (!location.Error) {
						// state.AOILibraryControl.remove(state.locations[state.locationIndex].id);
						state.locations.splice(state.locationIndex, 1);
						state.locationIndex = undefined;
						state.mode = Constants.MODES.None;
						state.method = 'deleteLocation';
						state = await resetDisplayedLocation(true);
					} else {
						confirmAlert({
							title: `Delete Location`,
							message: !isBlank(location) ? JSON.stringify(location.Error) : 'No response from server',
							closeOnEscape: false,
							closeOnClickOutside: false,
							buttons: [
								{
									label: 'Close',
									onClick: _.noop(),
								},
							],
						});
					}
				});
			};

			confirmAlert({
				title: `Delete ${state.location.name}`,
				message: `Are you certain that you want to delete the location ${state.location.name}?  This cannot be reversed and, if added again, all imagery will need to be reaquired and processed.`,
				closeOnEscape: false,
				closeOnClickOutside: false,
				buttons: [
					{
						label: 'Yes, I understand',
						onClick: deleteThisLocation,
					},
					{
						label: 'No, not right now.',
						onClick: _.noop,
					},
				],
			});
		} else {
			confirmAlert({
				title: `Delete Location`,
				message: 'Not authorized',
				closeOnEscape: false,
				closeOnClickOutside: false,
				buttons: [
					{
						label: 'Close',
						onClick: _.noop(),
					},
				],
			});
		}

		return state;
	};

	const modifyLocation = async () => {
		let state = appState;

		if (state.userCanEdit) {
			state.originalLocation = window.structuredClone(state.location);

			state.mode = Constants.MODES.Update;
			setcloseLocationPopupClicked(false);
			await updateState(state, 'modifyLocation');
		} else {
			confirmAlert({
				title: `Update Location`,
				message: 'Not authorized',
				closeOnEscape: false,
				closeOnClickOutside: false,
				buttons: [
					{
						label: 'Close',
						onClick: _.noop,
					},
				],
			});
		}

		return state;
	};

	// const setLocation = async (location) => {
	// 	let state = appState;
	// 	state.location = location;
	// 	await updateState(state, 'setLocation');
	// 	//state.dropPinControl.updateLocationName(location.name);
	// 	//state.dropPinControl.show();
	// };

	const resetDisplayedLocation = async (removePolygon = false) => {
		let state = appState;
		log(`resetDisplayedLocation`, 'removePolygon', removePolygon, 'state', state);

		state.mode = Constants.MODES.Closing;

		if (removePolygon && state.draw) {
			const id = state.draw.getAll().features.pop().id;
			state.draw.changeMode('direct_select', { featureId: id });
			log(`resetDisplayedLocation::drawMode is ${state.draw.getMode()}`);
			state.selectedGeometry = undefined;
			state.draw.delete(id);
		}

		state = await resetImagery(true, state);

		if (state.userCanNavigate) {
			state.hudControl.hide();
			// state.AOILibraryControl.clearSelect();
			// state.AOILibraryControl.show();
		}

		state.previousLocationsId = state.location.locationsId;
		// state.locationIndex = undefined;

		// state.location = undefined;
		state.mode = Constants.MODES.None;

		state.analysis = 'trueColour';
		//state.dropPinControl.hide();
		state = await updateState(state, 'resetDisplayedLocation');
		abortLoading = true;
		showNavigator.current = false;
		setSelectedLayers([]);
		setcloseLocationPopupClicked(true);
		return state;
	};

	const setDescription = async (description) => {
		let state = appState;
		state.location.description = description || state.description || state.name;
		await updateState(state, 'setDescription');
	};
	let defaultLocation = {
		relocationZoom: Constants.RELOCATION_ZOOM,
		setLocationName: setLocationName,
		setDescription: setDescription,
		// setStartDateFilter: setStartDateFilter,
		dateRange: [
			{
				start_date: subDays(new Date(), { years: 7 }),
				end_date: endOfYear(new Date()),
			},
		],
		startDate: addDays(new Date(), { months: -1 }),
		endDate: addDays(new Date(), { months: 3 }),
		name: null,
		description: null,
		imagery: [],
		polygonArea: 0,
		publicAccess: false,
		ower: '',
		groups: [],
		members: [],
		processS2: false,
		processS3: true,
		close: resetDisplayedLocation,
		modify: () => modifyLocation(appState, setcloseLocationPopupClicked, updateState, confirmAlert),
		delete: deleteLocation,
		setAnalysis: setAnalysis,
		hasImagery: false,
	};

	const [appState, setAppState] = useState({
		doc: undefined,
		userMode: 'basic',
		userCanEdit: false,
		userIsAdmin: false,
		version: localStorage.getItem('version') || '1.0.0',
		clientSettings: undefined,
		mode: Constants.MODES.None,
		locationIndex: undefined,
		locations: [],
		location: defaultLocation,
		originalLocation: {},
		selectedGeometry: undefined,
		ruler: undefined,
		pins: [],
		userPins: [],
		pinDropped: false,
		analysis: 'trueColour',
		images: {},
		imageryLayers: [],
		lastOrdinal: 0,
		firstOrdinal: 0,
		thumbnailRequests: [],
		layerPopup: {
			className: 'mapPoint',
			anchor: 'bottom',
			maxWidth: Constants.HOVER_POPUP_WIDTH,
			closeOnClick: false,
			closeButton: false,
			closeOnMove: false,
			active: false,
		},
		swiperDecorationsLength: Constants.SWIPER_BUTTON_WIDTH + Constants.SWIPER_SLIDE_FIRST_OFFSET + Constants.SWIPER_BUTTON_WIDTH + Constants.THUMBNAIL_MARGIN,
		swiperDivisor: Constants.THUMBNAIL_WIDTH - Constants.SWIPER_SLIDE_FIRST_OFFSET + Constants.THUMBNAIL_MARGIN,
		is3D: false,
		exportLocationData: false,
		exportRange: {},
		user: {},
		users: [],
		hasTrendData: false,
		isTrends: true,
		trendData: [],
		artLocations: [],
		artPins: [],
		darkTheme: createTheme({
			palette: {
				mode: 'dark',
			},
		}),
		//colorWorker: new Worker('workers/color.js'),
	});

	let defaultDate = '';
	let selectedLocation = appState ? appState.location : null;
	const [selectedDate, setSelectedDate] = useState(defaultDate);

	if (!defaultDate && selectedLocation) {
		// let formattedDate = format(defaultDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
		let satelliteKey = '';
		if (satelliteView === SATELLITES.copernicus) {
			satelliteKey = 'S3';
		} else if (satelliteView === SATELLITES.planet) {
			satelliteKey = 'PSD';
		}

		if (selectedLocation && selectedLocation.satellites && selectedLocation.satellites[satelliteKey] && selectedLocation.satellites[satelliteKey].dates) {
			let index = selectedLocation.satellites[satelliteKey] && selectedLocation.satellites[satelliteKey].dates[selectedLocation.satellites[satelliteKey] && selectedLocation.satellites[satelliteKey].dates.length - 1];
			if (!index) {
				index = format(new Date(), Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
				defaultDate = dayjs(format(new Date(parseISO(index)), Constants.DATE_FORMATS.SHORT_DATE_FORMAT));
			} else {
				defaultDate = dayjs(format(new Date(parseISO(index)), Constants.DATE_FORMATS.SHORT_DATE_FORMAT));
			}

			defaultDate = dayjs(format(new Date(parseISO(index)), Constants.DATE_FORMATS.SHORT_DATE_FORMAT));
			if (!selectedDate) {
				setSelectedDate(defaultDate);
			}
		}
	}

	//state.colorWorker.onmessage = (event) => colorWorkerHandler(event);

	// let componentIsMounted ;
	// must not be a state variable
	let abortLoading = false,
		loadingAborted = false;
	// }

	// General App Methods

	const updateState = async (state, method) => {
		let shouldUpdateState = true;
		const stateCopy = { ...state };

		if (_.isEqual(state, appState)) {
			if (appState.method === method) {
				shouldUpdateState = false;
			}
		}

		state.method = method;
		// setAppState(state); // majde added

		if (shouldUpdateState) {
			setAppState({ ...state }); // not sure it updates state!!
			if (Constants.DEV) {
				console.log('state update', stateCopy);
			}
			return stateCopy;
		}
		if (state.imageryDate !== appState.imageryDate) {
			stateCopy.imageryDate = appState.imageryDate;
			if (Constants.DEV) {
				console.log('state update', stateCopy);
			}

			setAppState({ ...state });
			return stateCopy;
		}

		// while (!stateSet) {//once deleted app worled
		//   await sleep(10);
		// }

		//log(`updateState: called from ${method}`);
		return state;
	};

	const sortByName = (a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0);

	const getUserSettings = async (state = appState) => {
		const refreshSettings = async (state = appState) => {
			const getAppSettings = async () => {
				const version = state.version,
					clientSettings = await fetch({ url: `getAppSettings` });

				if (clientSettings) {
					if (state.version !== clientSettings[Constants.VERSION]) {
						state.version = clientSettings[Constants.VERSION];
					}
					if (version && version !== '1.0.0' && version !== clientSettings[Constants.VERSION]) {
						confirmAlert({
							title: Constants.TITLE,
							message: `We've made some updates to ${Constants.TITLE}.  Please update to v${clientSettings[Constants.VERSION]}${Constants.STAGING ? ' STAGING' : Constants.DEV ? ' DEV' : Constants.TEST ? ' TEST' : ''} to refresh your browser.`,
							closeOnEscape: false,
							closeOnClickOutside: false,
							buttons: [
								{
									label: `Update to v${clientSettings[Constants.VERSION]}${Constants.BETA ? ' BETA' : Constants.DEV ? ' DEV' : Constants.TEST ? ' TEST' : ''}`,
									onClick: () => {
										window.localStorage.setItem('version', clientSettings[Constants.VERSION]);
										window.localStorage.removeItem('releaseNotes');
										window.location.reload(true);
									},
								},
							],
						});
					} else if (!version || version === '1.0.0') {
						window.localStorage.setItem('version', clientSettings[Constants.VERSION]);
						window.history.replaceState({}, '', '/');
						window.location.reload();
					} else if (!window.localStorage.getItem('releaseNotes') && clientSettings.RELEASE_NOTES && clientSettings.RELEASE_NOTES[version]) {
						let message = (
							<div className="releaseNotes">
								{Object.keys(clientSettings.RELEASE_NOTES)
									.reverse()
									.map((version) => (
										<div key={version}>
											<h3>
												v{version} - {clientSettings.RELEASE_NOTES[version].date}
											</h3>
											{clientSettings.RELEASE_NOTES[version].notes.map((note, _index) => (
												<p key={_index}>{note}</p>
											))}
										</div>
									))}
							</div>
						);
						confirmAlert({
							title: `Release Notes`,
							childrenElement: () => message,
							closeOnEscape: false,
							closeOnClickOutside: false,
							buttons: [
								{
									label: 'Continue',
									onClick: () => window.localStorage.setItem('releaseNotes', version),
								},
							],
						});
					}
				} else {
					log('getAppSettings: No client settings found.  API server may not be running. Redirecting to login page.');
					window.history.replaceState({}, '', '/');
					window.location.reload();
				}

				return state;
			};

			await getAppSettings();
			let user = await fetch({ url: `getUserSettings` });

			// if (user.enabled) {
			if (_.includes(user.enabled, Constants.VERSION.split('_')[0].toLowerCase())) {
				let locations = await fetch({
					url: `getLocations`,
					data: { mode: Constants.VERSION },
				});

				if (!_.isEqual(_.map(locations, 'locationsId'), _.map(state.locations, 'locationsId'))) {
					_.differenceBy(state.locations, locations, 'locationsId').forEach((_location) => {
						// state.AOILibraryControl.add(_location);
					});

					state.locations = locations.map((_location) => {
						return {
							..._location,
							dateRange: [
								{
									start_date: new Date(_location.dateRange[0].start_date),
									end_date: new Date(_location.dateRange[0].end_date),
								},
							],
						};
					});

					_.differenceBy(locations, state.locations, 'locationsId').forEach((_location) => {
						// state.AOILibraryControl.remove(_location);
					});
				}

				state.user = user;
				state.users = await fetch({ url: 'getUsers' });
				state.userMode = _.includes(user.groups, 'expert') ? 'expert' : _.includes(user.groups, 'pro') ? 'pro' : 'basic';
				state.userCanEdit = !Constants.DEMO && !_.includes(user.groups, 'viewer');
				state.userIsAdmin = _.includes(user.groups, 'admin');
				state.userCanNavigate = isBlank(user.restrictedAreas) && state.locations.length > 1;
				// await updateState(state, "getUserSettings"); //maybe need to uncomment later  i comment
			} else {
				if (isSafari()) {
					setTimeout(async () => {
						let user = await fetch({ url: `getUserSettings` });

						if (_.includes(user.enabled, Constants.VERSION.split('_')[0].toLowerCase())) {
							let locations = await fetch({
								url: `getLocations`,
								data: { mode: Constants.VERSION },
							});

							if (!_.isEqual(_.map(locations, 'locationsId'), _.map(state.locations, 'locationsId'))) {
								_.differenceBy(state.locations, locations, 'locationsId').forEach((_location) => {
									// state.AOILibraryControl.add(_location);
								});

								state.locations = locations.map((_location) => {
									return {
										..._location,
										dateRange: [
											{
												start_date: new Date(_location.dateRange[0].start_date),
												end_date: new Date(_location.dateRange[0].end_date),
											},
										],
									};
								});

								_.differenceBy(locations, state.locations, 'locationsId').forEach((_location) => {
									// state.AOILibraryControl.remove(_location);
								});
							}

							state.user = user;
							state.users = await fetch({ url: 'getUsers' });
							state.userMode = _.includes(user.groups, 'expert') ? 'expert' : _.includes(user.groups, 'pro') ? 'pro' : 'basic';
							state.userCanEdit = !Constants.DEMO && !_.includes(user.groups, 'viewer');
							state.userIsAdmin = _.includes(user.groups, 'admin');
							state.userCanNavigate = isBlank(user.restrictedAreas) && state.locations.length > 1;
							// await updateState(state, "getUserSettings"); //maybe need to uncomment later  i comment
						} else {
							log('getUserSettings: returned user is empty or no longer authenticated.  Redirecting to login page.');
							localStorage.removeItem('tokens');
							window.history.replaceState({}, '', '/');
							window.location.reload();
						}
					}, 500);
				} else {
					log('getUserSettings: returned user is empty or no longer authenticated.  Redirecting to login page.');
					localStorage.removeItem('tokens');
					window.history.replaceState({}, '', '/');
					window.location.reload();
				}
			}

			return state;
		};

		state = await refreshSettings(state);

		// if (!componentIsMounted) {
		if (!state.doc || !state.map) {
			state.doc = document;
			state.map = null;
			// await updateState(state, 'getUserSettings:: !componentIsMounted');
			state = await createMapBox(state);
			// await updateState(state, 'getUserSettings:: !componentIsMounted');
			// componentIsMounted = true;
			// log('componentIsMounted', componentIsMounted);
		}

		// todo disable the interval if the user is creating or modifying a location, or adding a pin, and then re-enable it when they are done
		state.refreshInterval = setInterval(async (state = appState) => {
			const refreshSettingsResponse = await refreshSettings(state);
			let haveDifference = false;

			let keys = Object.keys(state);
			keys.map((key) => {
				if (_.isEqual(state[key], refreshSettingsResponse[key])) {
					return key;
				} else {
					haveDifference = true;
					return key;
				}
			});

			if (haveDifference) {
				await updateState(refreshSettingsResponse, 'getUserSettings::interval');
			}
		}, 60000);
		// await updateState(state, 'getUserSettings::interval');
		return state;
	};
	const isSafari = () => {
		let browser = navigator.userAgent.toLowerCase();
		if (browser.indexOf('safari') !== -1) {
			if (browser.indexOf('chrome') > -1) {
				return false;
			} else {
				return true;
			}
		}
	};
	// const updateUserSettings = async (settings, state = appState) => {
	//   if (settings) {
	//     state = {
	//       ...state,
	//       ...settings,
	//     };

	//     await updateState(state, "updateUserSettings");
	//   }

	//   log(
	//     await fetch({
	//       url: `updateUserSettings`,
	//       data: {
	//         userPins: state.user.userPins,
	//       },
	//     })
	//   );

	//   return state;
	// };

	// Location related methods

	const setDateRange = async ([start, end]) => {
		let state = appState;

		if (start) {
			state.location.dateRange[0].start_date = start;
			state.location.startDate = start;
		}

		if (end) {
			state.location.dateRange[0].end_date = end;
			state.location.endDate = end;
		}

		if (start || end) {
			await updateState(state, `'setDateRange' ${start || end}`);
		}

		return state;
	};

	const setAccess = async (access) => {
		let state = appState;
		state.location.publicAccess = access === 'publicAccess';
		await updateState(state, `setAccess  ${state.location.publicAccess}`);

		// if (access === 'publicAccess') {
		// 	state.doc.querySelector('.publicAccess').classList.remove('hidden');
		// } else {
		// 	state.doc.querySelector('.publicAccess').classList.add('hidden');
		// }
	};

	const setMembers = async (members) => {
		let state = appState;

		if (members.length === 1 && members[0] === 'Everyone') {
			state.location.members = [];
		} else if (members.length > 0 && _.includes(members, 'Everyone')) {
			state.location.members = _.without(members, 'Everyone');
		} else {
			state.location.members = members;
		}

		await updateState(state, 'setMembers');
		return members;
	};

	// const setExportRange = async ([start, end]) => {
	// 	let state = { ...appState };
	// 	if (start) {
	// 		state.exportRange.startDate = start;
	// 	}

	// 	if (end) {
	// 		state.exportRange.endDate = end;
	// 	}
	// 	if (start || end) {
	// 		await updateState(state, `setExportRange ${start || end}`);
	// 	}

	// 	return state;
	// };

	const setStartMonthFilter = async (date, resetting = false) => {
		let state = { ...appState };

		if (isDate(date)) {
			// preserve existing endDate to revert to if the selected date has no imagery
			state.prevEndDate = state.location.endDate;
			state.location.startDate = startOfMonth(date);
			state.location.endDate = endOfMonth(date);

			if (!resetting) {
				abortLoading = false;
				loadingAborted = false;
				state = await displayImagery('setStartMonthFilter', state);
			}

			if (selectedLayers.length > 0) {
				//reset selected layers when we change month
				setSelectedLayers([]);
			}

			await updateState(state, 'setStartMonthFilter');
		}

		return state;
	};

	const setStartMonthFilterNew = async (date) => {
		let state = { ...appState };

		if (isDate(date)) {
			if (locationSelectedDate) {
				if (getMonth(locationSelectedDate) === getMonth(date) && getYear(locationSelectedDate) === getYear(date)) {
					state.prevEndDate = locationSelectedDate;
				} else {
					state.prevEndDate = state.location.endDate;
				}
				setLoading(true);
				state = await displayImageryNew('setStartMonthFilterNew', state, formatDate(date, Constants.DATE_FORMATS.SHORT_DATE_FORMAT), true, false);
				setLoading(false);
			} else {
				state.prevEndDate = state.location.endDate;
				state.location.startDate = startOfMonth(date);
				state.location.endDate = endOfMonth(date);
				setLocationSelectedDate(date);
				setLoading(true);
				state = await displayImageryNew('setStartMonthFilterNew', state, formatDate(date, Constants.DATE_FORMATS.SHORT_DATE_FORMAT), true, false);
				setLoading(false);
			}
			await setLocationSelectedDate(date);

			state = await updateState(state, 'setStartMonthFilter');
		}

		return state;
	};

	// const activateTimeSeries = (event) => {
	// 	let state = appState;
	// 	state.doc.querySelectorAll('.imagerySelectorButton:not(.timeSeriesDateRange)').forEach((selector) => selector.classList.add('disabled'));
	// 	state.doc.querySelectorAll('.dateRanges, .timeSeriesDateRange').forEach((selector) => selector.classList.remove('hidden'));
	// };

	// const setTimeSeries = (event) => {
	// 	let state = appState;
	// 	state.doc.querySelectorAll('.imagerySelectorButton:not(.timeSeriesDateRange)').forEach((selector) => selector.classList.remove('disabled'));
	// 	state.doc.querySelectorAll('.dateRanges, .timeSeriesDateRange').forEach((selector) => selector.classList.add('hidden'));
	// };

	// const startTimeSeries = async (event) => {
	// 	let state = appState;
	// 	state.timeSeries = true;
	// 	await updateState(state, 'startTimeSeries');
	// };

	// const stopTimeSeries = async (event) => {
	// 	let state = appState;
	// 	state.timeSeries = false;
	// 	await updateState(state, 'stopTimeSeries');
	// };

	const getLocation = async (locationsId) => {
		log(`getLocation`, 'locationsId', locationsId);
		return await fetch({
			url: `getLocations`,
			data: { locationsId: locationsId },
		}).then(async (_location) => {
			if (_location && _location.dateRange && _location.dateRange.length > 0) {
				_location.dateRange = [
					{
						start_date: new Date(_location.dateRange[0].start_date),
						end_date: new Date(_location.dateRange[0].end_date),
					},
				];
			}

			// let state = appState;
			// state.location = _location;
			// await updateState(state, 'setLocation');
			return _location;
		});
	};

	const addLocation = async (from, stateCopy = null) => {
		//should be edit location
		let state = stateCopy ? stateCopy : appState;
		log('addLocation', from);

		state.location = {
			...defaultLocation,
			owner: state.user.username,
			polygonArea: Number.parseFloat(area(state.selectedGeometry.geometry) / 1000000).toFixed(3),
		};

		if (
			state.userIsAdmin ||
			!(
				Number(state.location.polygonArea * Constants.UNITS[state.user.units][Constants.AREA_UNIT[state.user.units]].factor).toFixed(Constants.UNITS[state.user.units][Constants.AREA_UNIT[state.user.units]].decimals) > Constants.MAX_POLYGON_AREA ||
				Number(state.location.polygonArea * Constants.UNITS[state.user.units][Constants.AREA_UNIT[state.user.units]].factor).toFixed(2) < Constants.MIN_POLYGON_AREA
			)
		) {
			state.hudControl.update(
				`${state.location.name || 'Unnamed Location'} | ${formatDate(state.location.dateRange[0].start_date, Constants.DATE_FORMATS.DATE_FORMAT)} to ${formatDate(state.location.dateRange[0].end_date, Constants.DATE_FORMATS.DATE_FORMAT)} | ${
					Number(state.location.polygonArea * Constants.UNITS[state.user.units][Constants.AREA_UNIT[state.user.units]].factor).toFixed(Constants.UNITS[state.user.units][Constants.AREA_UNIT[state.user.units]].decimals) || 'N/A'
				} ${Constants.UNITS[state.user.units][Constants.AREA_UNIT[state.user.units]].label}`
			);
			state.hudControl.show();
		}

		state.mode = Constants.MODES.Add;
		// state.AOILibraryControl.hide();
		await updateState(state, 'addLocation');
		setcloseLocationPopupClicked(false);

		resizeMap(state);

		return state;
	};

	const closeModifyLocation = async (revert = false) => {
		let state = appState;

		if (revert) {
			state.location = state.originalLocation;
		}

		state.mode = Constants.MODES.View;

		await updateState(state, 'closeModifyLocation');
		setcloseLocationPopupClicked(true);
	};

	const createLocation = async () => {
		let state = appState;

		if (!state.location.name) {
			confirmAlert({
				title: `Create Location`,
				message: 'A name is required for this imagery',
				closeOnEscape: false,
				closeOnClickOutside: false,
				buttons: [
					{
						label: 'Close',
						onClick: _.noop(),
					},
				],
			});
		} else {
			const postData = {
				// usersId: state.user.usersId,
				name: state.location.name,
				description: state.location.description || state.location.name,
				dateRange: [
					{
						start_date: `${formatDate(state.location.dateRange[0].start_date, Constants.DATE_FORMATS.DATE_FORMAT)}T00:00:00Z`,
						end_date: `${formatDate(state.location.dateRange[0].end_date, Constants.DATE_FORMATS.DATE_FORMAT)}T23:59:59Z`,
					},
				],
				geometry: state.selectedGeometry,
				polygonArea: Number(state.location.polygonArea * Constants.UNITS['metric'][Constants.AREA_UNIT['metric']].factor).toFixed(Constants.UNITS['metric'][Constants.AREA_UNIT['metric']].decimals),
				publicAccess: state.location.publicAccess,
				owner: state.user.username,
				members: state.location.members,
				// usersId: state.user.usersId,
			};

			await fetch({ url: `createLocation`, data: postData }).then(async (_location) => {
				if (!isBlank(_location) && !_location.Error) {
					state.location = _location;
					state.location.dateRange = [
						{
							start_date: new Date(state.location.dateRange[0].start_date),
							end_date: new Date(state.location.dateRange[0].end_date),
						},
					];
					state.location.endDate = _location.dateRange[0].end_date;

					state.location.startDate = _location.dateRange[0].start_date;
					state.locations.push(_location);
					state.locations = state.locations.sort(sortByName);
					state.locationIndex = state.locations.findIndex((_location) => _location.name === state.location.name);
					state.mode = Constants.MODES.None; //original
					// state.mode = Constants.MODES.View;
					// state.AOILibraryControl.add(state.location);

					state.selectedGeometry = undefined;
					await updateState(state, 'createLocation');
					state = resetDisplayedLocation(false);
					setcloseLocationPopupClicked(true);
					setShowNotification({
						show: true,
						msg: 'New AOI Created Successfully. Data is being processed, Please check back later.',
						severity: 'success',
					});
				} else {
					confirmAlert({
						title: `Create Location`,
						message: !isBlank(_location) ? JSON.stringify(_location.Error) : 'No response from server',
						closeOnEscape: false,
						closeOnClickOutside: false,
						buttons: [
							{
								label: 'Close',
								onClick: _.noop(),
							},
						],
					});
				}
			});
		}

		return state;
	};

	const updateLocation = async () => {
		let state = appState;

		if (
			state.userCanEdit &&
			(state.location.name !== state.originalLocation.name ||
				state.location.description !== state.originalLocation.description ||
				state.location.dateRange[0].start_date.toISOString() !== state.originalLocation.dateRange[0].start_date.toISOString() ||
				state.location.dateRange[0].end_date.toISOString() !== state.originalLocation.dateRange[0].end_date.toISOString() ||
				state.location.publicAccess !== state.originalLocation.publicAccess ||
				_.difference(state.location.members, state.originalLocation.members).length > 0)
		) {
			const updateThisLocation = async () => {
				await fetch({
					url: `modifyLocation`,
					data: {
						locationsId: state.location.locationsId,
						name: state.location.name,
						description: state.location.description || state.location.name,
						dateRange: [
							{
								start_date: `${formatDate(state.location.dateRange[0].start_date, Constants.DATE_FORMATS.DATE_FORMAT)}T00:00:00Z`,
								end_date: `${formatDate(state.location.dateRange[0].end_date, Constants.DATE_FORMATS.DATE_FORMAT)}T23:59:59Z`,
							},
						],
						polygonArea: Number(state.location.polygonArea * Constants.UNITS[state.user.units][Constants.AREA_UNIT[state.user.units]].factor).toFixed(Constants.UNITS[state.user.units][Constants.AREA_UNIT[state.user.units]].decimals),
						publicAccess: state.location.publicAccess,
						members: state.location.members,
					},
				}).then(async (_location) => {
					if (!_location.Error) {
						state.modifyLocation = false;
						await updateState(state, 'updateLocation');
						// state.AOILibraryControl.update(state.location);
						closeModifyLocation(false);
						setcloseLocationPopupClicked(true);
					} else {
						confirmAlert({
							title: `Modify Location`,
							message: !isBlank(_location) ? _location.Error : 'No response from server',
							closeOnEscape: false,
							closeOnClickOutside: false,
							buttons: [
								{
									label: 'Close',
									onClick: () => closeModifyLocation(true),
								},
							],
						});
					}
				});
			};

			let message = `Are you certain that you want to update the location ${state.location.name}?`,
				okLabel = 'Yes';

			if (state.location.dateRange[0].start_date.toISOString() !== state.originalLocation.dateRange[0].start_date.toISOString() || state.location.dateRange[0].end_date.toISOString() !== state.originalLocation.dateRange[0].end_date.toISOString()) {
				message += '\n\nYou have changed the date ranges. This will affect the imagery available for this location and the calculated averages, which will take several hours to complete.  Changing back will not be possible until the calculations are complete.';
				okLabel = 'Yes, I understand';
			}

			if (state.location?.members?.length !== state.originalLocation?.members?.length) {
				message += '\n\nYou have changed who you have shared this with. Users that you have removed will no longer be able to see this location.  Users that you have added will now be able to see this location.';
				okLabel = 'Yes, I understand';
			}

			confirmAlert({
				title: `Update ${state.location.name}`,
				message: message,
				closeOnEscape: false,
				closeOnClickOutside: false,
				buttons: [
					{
						label: okLabel,
						onClick: updateThisLocation,
					},
					{
						label: 'No, not right now.',
						onClick: _.noop,
					},
				],
			});
		} else if (!state.userCanEdit) {
			confirmAlert({
				title: `Update Location`,
				message: 'Not authorized',
				buttons: [
					{
						label: 'Close',
						onClick: _.noop,
					},
				],
			});
		} else {
			confirmAlert({
				title: `Update Location`,
				message: 'No changes detected',
				closeOnEscape: false,
				closeOnClickOutside: false,
				buttons: [
					{
						label: 'Close',
						onClick: () => closeModifyLocation(false),
					},
				],
			});
		}

		return state;
	};

	// const updateExportLocationData = async (value) => {
	// 	await updateState({ ...appState, exportLocationData: value }, 'updateExportLocationData');
	// };

	// const exportData = async () => {
	// 	let state = appState;

	// 	const cleanup = async () => {
	// 		state.exportLocationData = false;

	// 		await updateState(state, 'exportData');
	// 		state.doc.querySelectorAll('.imagerySelectorButton:not(.dateRangeControls)').forEach((selector) => selector.classList.remove('disabled'));
	// 		state.doc.querySelector('.export').classList.remove('green');
	// 		state.doc.querySelector('.export').classList.add('grey');
	// 		state.doc.querySelectorAll('.dateRanges, .dateRangeControls, .locationDateRange').forEach((selector) => selector.classList.add('hidden'));
	// 	};

	// 	if (isAuthorized(appState.user, ['expert'])) {
	// 		if (!state.exportLocationData) {
	// 			state.exportLocationData = true;

	// 			state.exportRange = {
	// 				startDate: state.location.dateRange[0].start_date,
	// 				endDate: state.location.dateRange[0].end_date,
	// 			};

	// 			await updateState(state, 'exportData');
	// 			state.doc.querySelectorAll('.imagerySelectorButton:not(.locationDateRange, .export)').forEach((selector) => selector.classList.add('disabled'));
	// 			state.doc.querySelector('.export').classList.remove('grey');
	// 			state.doc.querySelector('.export').classList.add('green');
	// 			state.doc.querySelectorAll('.dateRanges, .locationDateRange').forEach((selector) => selector.classList.remove('hidden'));
	// 		} else {
	// 			let startDate = state.exportRange.startDate ? state.exportRange.startDate : state.location.dateRange[0].start_date;
	// 			let endDate = state.exportRange.endDate ? state.exportRange.endDate : state.location.dateRange[0].end_date;

	// 			await fetch({
	// 				url: `exportData`,
	// 				data: {
	// 					locationsId: state.location.locationsId,
	// 					startDate: startDate,
	// 					endDate: endDate,
	// 					email: state.user.email,
	// 				},
	// 			}).then(async (_response) => {
	// 				if (_response.Success) {
	// 					confirmAlert({
	// 						title: 'Data Export',
	// 						message: _response.Success,
	// 						closeOnEscape: false,
	// 						closeOnClickOutside: false,
	// 						buttons: [
	// 							{
	// 								label: 'Close',
	// 								onClick: cleanup,
	// 							},
	// 						],
	// 					});
	// 				} else {
	// 					confirmAlert({
	// 						title: `Data Export`,
	// 						message: _response.Error,
	// 						closeOnEscape: false,
	// 						closeOnClickOutside: false,
	// 						buttons: [
	// 							{
	// 								label: 'Close',
	// 								onClick: cleanup,
	// 							},
	// 						],
	// 					});
	// 				}
	// 			});
	// 		}
	// 	}
	// };

	const exportDataRanges = async (startDate, endDate) => {
		if (!startDate || !endDate) {
			return;
		}

		if (isAuthorized(appState.user, ['expert'])) {
			const _response = await fetch({
				// dont send satellite param
				url: `exportData`,
				data: {
					locationsId: appState.location.locationsId,
					startDate: format(startDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT),
					endDate: format(endDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT),
					email: appState.user.emaisl,
				},
			});
			// }).then(async (_response) => {
			if (_response.Success) {
				// confirmAlert({
				// 	title: 'Data Export',
				// 	message: _response.Success,
				// 	closeOnEscape: false,
				// 	closeOnClickOutside: false,
				// 	buttons: [
				// 		{
				// 			label: 'Close',
				// 			onClick: () => {},
				// 		},
				// 	],
				// });
				// setShowNotification({
				// 	show: true,
				// 	msg: _response.Success,
				// 	severity: 'success',
				// });
				return { isSuccess: true, msg: _response.Success };
				// setModalOpened(false);
			} else {
				// confirmAlert({
				// 	title: `Data Export`,
				// 	message: _response.Error,
				// 	closeOnEscape: false,
				// 	closeOnClickOutside: false,
				// 	buttons: [
				// 		{
				// 			label: 'Close',
				// 			onClick: () => {},
				// 		},
				// 	],
				// });

				// setShowNotification({
				// 	show: true,
				// 	msg: _response.Error,
				// 	severity: 'error',
				// });

				return { isSuccess: false, msg: _response.Error };
			}
			// });
		}
	};

	// const cancelImagerySelectorAction = async () => {
	// 	let state = appState;

	// 	if (state.modifyLocation) {
	// 		state.modifyLocation = false;
	// 		state = setDateRange([state.locations[state.locationIndex].dateRange[0].start_date, state.locations[state.locationIndex].dateRange[0].end_date], state);
	// 	} else if (state.exportLocationData) {
	// 		state.exportLocationData = false;
	// 		state.doc.querySelector('.export').classList.remove('green');
	// 		state.doc.querySelector('.export').classList.add('grey');
	// 	}

	// 	await updateState(state, 'cancelImagerySelectorAction');
	// 	state.doc.querySelectorAll('.imagerySelectorButton:not(.dateRangeControls)').forEach((selector) => selector.classList.remove('disabled'));
	// 	state.doc.querySelectorAll('.dateRanges, .dateRangeControls, .locationDateRange').forEach((selector) => selector.classList.add('hidden'));

	// 	return state;
	// };

	// Navigation scrolling related methods

	// const scrollRight = async () => {
	// 	let state = appState;
	// 	const getNextImagery = (nextDateToRequest) => state.location.imagery.findIndex((_imagery) => _imagery.date === nextDateToRequest),
	// 		swiper = document.querySelector('.swiper').swiper;

	// 	// if we are at the end of the list, request more imagery
	// 	log('scrollRight', 'state.lastDateDisplayed', state.lastDateDisplayed, 'state.lastCachedDate', state.lastCachedDate);

	// 	if (!state.imageryAtEnd) {
	// 		swiper.slideNext();
	// 		state.doc.querySelector('.imageryLeft').classList.remove('hidden');

	// 		let nextDateToRequest = formatDate(subDays(parseDate(state.lastCachedDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT, new Date()), { days: 1 }), Constants.DATE_FORMATS.SHORT_DATE_FORMAT),
	// 			imageryIndex = getNextImagery(nextDateToRequest);

	// 		while (imageryIndex === -1 && Number(nextDateToRequest) > state.location.firstImagery) {
	// 			nextDateToRequest = formatDate(subDays(parseDate(nextDateToRequest, Constants.DATE_FORMATS.SHORT_DATE_FORMAT, new Date()), { days: 1 }), Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
	// 			imageryIndex = getNextImagery(nextDateToRequest);
	// 		}

	// 		if (imageryIndex >= 0) {
	// 			let imagery = state.location.imagery[imageryIndex];

	// 			if (Number(nextDateToRequest) > state.location.firstImagery && imagery.status !== 'missing' && !imagery?.analyses) {
	// 				log('scrollRight', 'fetching analyses for ', nextDateToRequest);

	// 				imagery.analyses = await fetch({
	// 					url: `getAnalyses`,
	// 					data: { analysesId: imagery.analysesId },
	// 				});
	// 			}

	// 			state.lastCachedDate = nextDateToRequest;
	// 			state.firstCachedDate = formatDate(subDays(parseDate(state.firstCachedDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT, new Date()), { days: 1 }), Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
	// 			state.lastDateDisplayed = formatDate(subDays(parseDate(state.lastDateDisplayed, Constants.DATE_FORMATS.SHORT_DATE_FORMAT, new Date()), { days: 1 }), Constants.DATE_FORMATS.SHORT_DATE_FORMAT);

	// 			if (Number(nextDateToRequest) === state.location.lastImagery) {
	// 				log('scrollRight', 'imageryAtEnd', true);
	// 				state.imageryAtEnd = true;
	// 			} else {
	// 				if (imagery.status !== 'missing' && imagery.analyses.analyses) {
	// 					state.location.imagery[imageryIndex].analyses = LZString.decompressFromBase64(imagery.analyses.analyses)
	// 						.split('!')
	// 						.map((_prop) => _prop.split('/').map((__prop, _i) => (_i !== 3 ? Number(__prop) : __prop)));

	// 					state.location.imagery[imageryIndex] = await processAnalyses(state.location.imagery[imageryIndex], state);

	// 					if (state.images && state.images[state.location.imagery[imageryIndex].date]) {
	// 						if (state.images[state.location.imagery[imageryIndex].date].thumbnails.trueColour.thumbnail) {
	// 							URL.revokeObjectURL(state.images[state.location.imagery[imageryIndex].date].thumbnails.trueColour.thumbnail);
	// 						}
	// 						if (state.images[state.location.imagery[imageryIndex].date].thumbnails.bgi.thumbnail) {
	// 							URL.revokeObjectURL(state.images[state.location.imagery[imageryIndex].date].thumbnails.bgi.thumbnail);
	// 						}
	// 						if (state.images[state.location.imagery[imageryIndex].date].thumbnails.bloom.thumbnail) {
	// 							URL.revokeObjectURL(state.images[state.location.imagery[imageryIndex].date].thumbnails.bloom.thumbnail);
	// 						}
	// 					}

	// 					state = await createThumbnails(state.location.imagery[imageryIndex], swiper, state);
	// 				} else {
	// 					state.lastDateDisplayed = formatDate(subDays(parseDate(state.lastDateDisplayed, Constants.DATE_FORMATS.SHORT_DATE_FORMAT, new Date()), { days: 1 }), Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
	// 					log('displayImagery::loop::noAnalyses', state.location.imagery[imageryIndex].date);
	// 					state = await createThumbnails(state.location.imagery[imageryIndex], null, state);
	// 				}
	// 			}
	// 		} else {
	// 			log('scrollRight', 'imageryAtEnd', true);
	// 			state.imageryAtEnd = true;
	// 			state.doc.querySelector('.imageryRight').classList.add('hidden');
	// 		}
	// 	} else {
	// 		log('scrollRight discarding request', 'state.lastDateDisplayed', state.lastDateDisplayed, 'state.firstCachedDate', state.firstCachedDate);
	// 	}
	// 	await updateState(state, 'scrollRight:');
	// };

	// const scrollLeft = () => {
	// 	let state = appState;

	// 	if (state.firstCachedDate < state.firstDateDisplayed) {
	// 		const swiper = document.querySelector('.swiper').swiper;

	// 		log('scrollLeft', 'state.firstDateDisplayed', state.firstDateDisplayed, 'state.firstCachedDate', state.firstCachedDate);
	// 		state.firstCachedDate = formatDate(addDays(parseDate(state.firstCachedDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT, new Date()), { days: 1 }), Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
	// 		state.lastCachedDate = formatDate(addDays(parseDate(state.lastCachedDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT, new Date()), { days: 1 }), Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
	// 		state.lastDateDisplayed = formatDate(addDays(parseDate(state.lastDateDisplayed, Constants.DATE_FORMATS.SHORT_DATE_FORMAT, new Date()), { days: 1 }), Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
	// 		swiper.slidePrev();
	// 		state.doc.querySelector('.imageryRight').classList.remove('hidden');

	// 		if (state.firstCachedDate === state.firstDateDisplayed) {
	// 			state.doc.querySelector('.imageryLeft').classList.add('hidden');
	// 		}
	// 	} else {
	// 		state.doc.querySelector('.imageryLeft').classList.add('hidden');
	// 	}
	// };

	// Imagery related methods

	// const colorWorkerHandler = (event) => {
	//   const sharpenedImageArray = event.data;
	//   log(sharpenedImageArray);
	// };

	const createThumbnails = async (imagery, swiper = null, state = appState) => {
		const createThumbnail = async (analyses, optimized = true) => {
			let thumbnail = '',
				unoptimizedCorner = [];

			// this block allows the raw colour values to be sharpened and have their contrast, brightness and saturation adjusted
			//const imageData = new Uint32Array(imagery.geoJSON.features.map(({ feature }) => parseInt(feature.properties.colour.substring(1), 16)));
			//state.colorWorker.postMessage({ imageData });

			const canvas = state.doc.createElement('canvas'),
				context = canvas.getContext('2d');

			canvas.width = imagery[SATELLITES.copernicus].columns;
			canvas.height = imagery[SATELLITES.copernicus].rows;

			context.fillStyle = 'transparent';

			for (let feature of imagery[SATELLITES.copernicus].geoJSON.features) {
				if (!abortLoading && !loadingAborted) {
					if (isBlank(unoptimizedCorner) && feature.properties.bgi !== 0) {
						unoptimizedCorner = [feature.properties.row, feature.properties.column];
					}

					if (optimized || !isBlank(unoptimizedCorner)) {
						try {
							switch (analyses) {
								case 'trueColour':
									context.strokeStyle = feature.properties.bgi !== 0 ? feature.properties.color : 'transparent';
									break;

								case 'bgi':
									context.strokeStyle = feature.properties.bgi !== 0 ? Constants.LEGENDS[SATELLITES.copernicus][analyses].find((_legend) => feature.properties.bgi === _legend.threshold).colour : 'transparent';
									break;

								case 'bloom':
									context.strokeStyle = feature?.properties?.bgi === 5 && feature?.properties?.bloom > 0 ? Constants.LEGENDS[SATELLITES.copernicus][analyses].find((_legend) => feature?.properties?.bloom >= _legend.threshold).colour : 'transparent';
									break;

								default:
									_.noop();
									break;
							}
						} catch (e) {
							console.error('createThumbnail', 'error', e);
						}

						context.beginPath();
						context.moveTo(feature.properties.column, feature.properties.row);
						context.lineTo(feature.properties.column + 1, feature.properties.row);
						context.stroke();
					}
				} else {
					break;
				}
			}
			if (!abortLoading && !loadingAborted) {
				const optimizedCanvas = state.doc.createElement('canvas'),
					optimizedContext = optimizedCanvas.getContext('2d');

				if (optimized) {
					optimizedCanvas.width = Constants.THUMBNAIL_WIDTH;
					optimizedCanvas.height = Constants.THUMBNAIL_HEIGHT;

					optimizedContext.scale(Constants.THUMBNAIL_WIDTH / imagery[SATELLITES.copernicus].columns, Constants.THUMBNAIL_HEIGHT / imagery[SATELLITES.copernicus].rows);
					optimizedContext.drawImage(canvas, 0, 0);
					thumbnail = optimizedCanvas.toDataURL();
				} else {
					optimizedCanvas.width = imagery[SATELLITES.copernicus].columns;
					optimizedCanvas.height = imagery[SATELLITES.copernicus].rows;
					optimizedContext.scale(1.5, 2);
					optimizedContext.drawImage(canvas, -unoptimizedCorner[0], -unoptimizedCorner[1]);
					thumbnail = optimizedCanvas.toDataURL();
				}
			}

			return thumbnail;
		};

		let date = new Date(imagery.timestamp ? imagery.timestamp : parseDate(imagery.date, Constants.DATE_FORMATS.SHORT_DATE_FORMAT, new Date()));

		if (!_.isObject(state.images)) {
			state.images = {};
		}
		if (!abortLoading && !loadingAborted) {
			state.images[imagery.date] = {
				key: imagery.date,
				date: date.toLocaleDateString(),
				time: date.toLocaleTimeString(),
				imagery: imagery,
				thumbnails: {
					trueColour: {
						imageKey: `${imagery.date}_trueColour`,
						thumbnail: !_.includes(['missing', 'gave_up', 'weatherOnly', 'planetOnly'], imagery.status) && imagery[SATELLITES.copernicus].geoJSON?.features ? await createThumbnail('trueColour') : Constants.BLANK,
						caption: _.includes(['missing', 'gave_up', 'weatherOnly'], imagery.status) ? `Data Unavailable` : imagery.status === 'planetOnly' ? 'Planet Available' : undefined,
					},
					bgi: {
						imageKey: `${imagery.date}_bgi`,
						thumbnail: !_.includes(['missing', 'gave_up', 'weatherOnly', 'planetOnly'], imagery.status) && imagery[SATELLITES.copernicus].geoJSON?.features ? await createThumbnail('bgi') : Constants.BLANK,
						caption: _.includes(['missing', 'gave_up', 'weatherOnly'], imagery.status) ? `Data Unavailable` : imagery.status === 'planetOnly' ? 'Planet Available' : undefined,
					},
					bloom: {
						imageKey: `${imagery.date}_bloom`,
						thumbnail: !_.includes(['missing', 'gave_up', 'weatherOnly', 'planetOnly'], imagery.status) && imagery[SATELLITES.copernicus].geoJSON?.features && imagery[SATELLITES.copernicus].geoJSON.features.some((_feature) => _feature?.properties?.bloom) ? await createThumbnail('bloom') : Constants.BLANK,
						caption: _.includes(['missing', 'gave_up', 'weatherOnly'], imagery.status)
							? `Data Unavailable`
							: imagery.status === 'planetOnly'
							? 'Planet Available'
							: !imagery[SATELLITES.copernicus].geoJSON?.features || !imagery[SATELLITES.copernicus].geoJSON?.features.some((_feature) => _feature?.properties?.bloom)
							? `Not Detected`
							: undefined,
					},
					weather: imagery[SATELLITES.copernicus].weather
						? {
								...imagery[SATELLITES.copernicus].weather,
								temperature_2m: Number((imagery[SATELLITES.copernicus].weather.temperature_2m * Constants.UNITS[state.user.units].Temp.factor).toFixed(Constants.UNITS[state.user.units].Temp.decimals)),
								windspeed_10m: Number((imagery[SATELLITES.copernicus].weather.windspeed_10m * Constants.UNITS[state.user.units].Wind.factor).toFixed(Constants.UNITS[state.user.units].Wind.decimals)),
								winddirection_10m: getWindDirection(imagery[SATELLITES.copernicus].weather.winddirection_10m),
						  }
						: {},
				},
			};

			if (swiper) {
				swiper.update();
			}

			state.analysis = appState.analysis;
			state.imageryDate = imagery.date;
			state.hasImageryDetails = imagery[SATELLITES.copernicus].analyses ? true : false;
		} else {
			state.imageryDate = {};
		}
		return state;
	};

	const processAnalyses = async (imagery) => {
		let features = Array(imagery[SATELLITES.copernicus].rows * imagery[SATELLITES.copernicus].columns),
			ordinal,
			row,
			column,
			colour,
			bgi,
			classification,
			bloom,
			longitude,
			latitude;

		for (let _index = 0; _index < imagery[SATELLITES.copernicus].analyses?.data?.length; _index++) {
			if (!abortLoading && !loadingAborted) {
				[ordinal, row, column, colour, bgi, classification, bloom, longitude, latitude] = imagery[SATELLITES.copernicus].analyses.data[_index];

				features[ordinal] = {
					type: 'Feature',
					geometry: point([longitude, latitude]).geometry,
					properties: {
						index: _index,
						centroid: new mapboxgl.LngLat(longitude, latitude),
						color: colour,
						bgi: bgi,
						classification: classification,
						bloom: bloom,
						row: row,
						column: column,
					},
				};
			} else {
				break;
			}
		}

		if (!abortLoading && !loadingAborted) {
			imagery[SATELLITES.copernicus].geoJSON = {
				type: 'FeatureCollection',
				features: features.filter((_feature) => _feature.properties),
			};
		} else {
			imagery[SATELLITES.copernicus].geoJSON = {
				type: 'FeatureCollection',
				features: [],
			};
		}

		return imagery;
	};

	const removeAllLayers = async () => {
		let state = { ...appState };
		if (!isBlank(state.imageryLayers)) {
			[...state.imageryLayers].forEach(async (_layer) => {
				if (state.map.getLayer(_layer.id)) {
					state.map.removeLayer(_layer.id).removeSource(_layer.id);
				}
			});
			state.imageryLayers = [];
			state = await updateState(state, 'removeAllLayers');
		}
		return state;
	};

	const resetImagery = (isClosing = false, state = appState) => {
		log('resetImagery', 'state', state, 'isClosing', isClosing);

		if (!isBlank(state.imageryLayers)) {
			[...state.imageryLayers].forEach(async (_layer) => {
				state = await removeLayer(_layer, state);
			});
		}

		if (state.map.getLayer('planetLayer')) {
			state.map.removeLayer('planetLayer');
			state.map.removeSource('planetSource');
		}

		state.map.resetNorthPitch();

		state.is3D = false;
		state.layerPopup.active = false;
		state.hasImageryDetails = false;

		if (isClosing) {
			closeTrends();

			if (state.images) {
				Object.keys(state.images).forEach((_imageDate) =>
					Object.keys(state.images[_imageDate].thumbnails).forEach((_imageType) => {
						if (state.images[_imageDate].thumbnails[_imageType].thumbnail !== 'Not Detected') {
							URL.revokeObjectURL(state.images[_imageDate].thumbnails[_imageType].thumbnail);
						}
					})
				);

				state.images = undefined;
			}

			state.trendData = undefined;
			state.isTrends = false;
			state.imagery = undefined;
			state.firstCachedDate = undefined;
			state.lastCachedDate = undefined;
			state.lastDateDisplayed = undefined;
			state.imageryAtEnd = false;
			state.hasTrendDataForNavigator = false;
		}

		if (state.doc.querySelector('.imageryLeft')) {
			state.doc.querySelector('.imageryLeft').classList.add('hidden');
		}
		if (state.doc.querySelector('.imageryRight')) {
			state.doc.querySelector('.imageryRight').classList.add('hidden');
		}
		return state;
	};

	const displayImagery = async (from, stateCopy = appState) => {
		let state = { ...stateCopy },
			itemsToDisplay = parseInt((window.innerWidth - state.swiperDecorationsLength) / state.swiperDivisor),
			request = {
				locationsId: state.location.locationsId,
				startDate: formatDate(state.location.startDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT),
				endDate: formatDate(state.location.endDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT),
			},
			firstAnalysesIndex = 0;

		log('displayImagery', from, 'hasState', !!state, 'for', itemsToDisplay, 'items: ', JSON.stringify(request), new Date().toLocaleTimeString());

		state.mode = Constants.MODES.View;
		setLoading(true);

		let imagery = await fetch({
			url: `getImagery`,
			data: request,
		});

		if (!isBlank(imagery)) {
			log(`displayImagery received ${imagery.length} items`);

			if (state.location.hasImagery) {
				state = resetImagery(false, state);
				state.lastCachedDate = undefined;
				state.images = {};
			}

			state.firstCachedDate = imagery[0].date;
			state.location.imagery = imagery;
			state.location.hasImagery = true;

			while (firstAnalysesIndex < state.location.imagery.length && !state.location.imagery[firstAnalysesIndex].analysesId && !state.location.imagery[firstAnalysesIndex].planetAnalysesId) {
				firstAnalysesIndex++;
			}

			if (firstAnalysesIndex !== state.location.imagery.length) {
				if (state.location.imagery[firstAnalysesIndex].analysesId) {
					state.location.imagery[firstAnalysesIndex].analyses = await fetch({
						url: `getAnalyses`,
						data: {
							analysesId: state.location.imagery[firstAnalysesIndex].analysesId,
							satellite: 'copernicus',
						},
					});
				}
			}

			let validImageryItems = 0;

			itemsToDisplay = 31;

			for (let imageryIndex = 0; validImageryItems < itemsToDisplay + 1 && imageryIndex < state.location.imagery.length; imageryIndex++) {
				if (!abortLoading && !loadingAborted) {
					let nextIndex = imageryIndex + 1;

					if (imageryIndex === firstAnalysesIndex) {
						firstAnalysesIndex = 0;
					}

					if (firstAnalysesIndex === 0 && imageryIndex !== state.location.imagery.length - 1 && state.location.imagery[nextIndex].analysesId) {
						state.location.imagery[nextIndex].analyses = await fetch({
							url: `getAnalyses`,
							data: {
								analysesId: state.location.imagery[nextIndex].analysesId,
								satellite: 'copernicus',
							},
						});
					}

					if (!isBlank(state.location.imagery[imageryIndex].analyses) || !isBlank(state.location.imagery[imageryIndex]?.planet?.analyses)) {
						if (!isBlank(state.location.imagery[imageryIndex].analyses)) {
							state.location.imagery[imageryIndex].analyses = LZString.decompressFromBase64(state.location.imagery[imageryIndex].analyses.analyses)
								.split('!')
								.map((_prop) => _prop.split('/').map((__prop, _i) => (_i !== 3 ? Number(__prop) : __prop)));
							state.location.imagery[imageryIndex] = await processAnalyses(state.location.imagery[imageryIndex], state);
						}

						state = await createThumbnails(state.location.imagery[imageryIndex], null, state);
						state.lastCachedDate = state.location.imagery[imageryIndex].date;
						validImageryItems++;
					} else {
						log('displayImagery::loop::noAnalyses', state.location.imagery[imageryIndex].date);
						state.location.imagery[imageryIndex].geoJSON = {
							type: 'FeatureCollection',
							features: [],
						};
						state.location.imagery[imageryIndex].status = 'missing';
						state = await createThumbnails(state.location.imagery[imageryIndex], null, state);
						state.lastCachedDate = state.location.imagery[imageryIndex].date;
						validImageryItems++;
					}
				} else {
					log('displayImagery::loop::cancelled', state.location.imagery[imageryIndex].date);
					break;
				}
			}

			if (!abortLoading && !loadingAborted) {
				state.hasImageryDetails = appState.hasImageryDetails;
				if (state.location.imagery && state.location.imagery[0] && state.location.imagery[itemsToDisplay - 1]) {
					state.firstDateDisplayed = state.location.imagery[0].date;
					state.lastDateDisplayed = state.location.imagery[itemsToDisplay - 1].date; //state.location.imagery[state.location.imagery.length - 1].date;
				}
			} else {
				state.images = [];
			}
		} else {
			let buttons = [
					{
						label: state.userCanNavigate && state.locations.length > 1 ? 'Close' : 'Retry',
						onClick: async () => {
							if (!state.location.hasImagery) {
								state = state.userCanNavigate && state.locations.length > 1 ? await resetDisplayedLocation(false) : await updateMapLocation('displayImageryDetails::noCachedData::retry');
							} else {
								state = await setStartMonthFilter(state.prevEndDate, true);
							}
						},
					},
				],
				message = state.location.hasImagery ? 'There is no imagery available for the selected month.' : 'Imagery is currently being prepared for this location. Please try again later';

			if (!state.location.hasImagery && state.userIsAdmin) {
				buttons.push({
					label: 'Delete Location',
					onClick: async () => {
						state = await deleteLocation(state, confirmAlert, resetDisplayedLocation);
						// await updateState(state, 'displayImageryDetails::noCachedData::delete');
						resetDisplayedLocation(false);
					},
				});

				message += ', or select Delete Location if you want to remove this location.';
			} else {
				message += '.';
			}

			confirmAlert({
				title: state.location.name,
				message: message,
				closeOnEscape: false,
				closeOnClickOutside: false,
				buttons: buttons,
			});
		}

		if (!abortLoading && !loadingAborted) {
			state.imageryDate = appState.imageryDate;

			if (state.lastCachedDate && !state.hasTrendDataForNavigator) {
				state = await getTrendData(state);
				//
			}

			if (!abortLoading && !loadingAborted && state.location?.imagery?.length > itemsToDisplay) {
				if (state.doc.querySelector('.imageryRight')) {
					state.doc.querySelector('.imageryRight').classList.remove('hidden');
				}
			} else {
				loadingAborted = true;
				abortLoading = false;
			}
			// state = await updateState(state, 'displayImagery::images');
		} else {
			loadingAborted = true;
			abortLoading = false;
		}
		state = await updateState(state, 'displayImagery::abortLoading');
		setLoading(false);
		return state;
	};

	const displayImageryNew = async (from, stateCopy = appState, date, isSameLocation = false, shouldLoadImageries = false) => {
		let state = { ...stateCopy };

		let startDate = date;

		if (state && state.images && state.images[startDate]) {
			return state;
		}

		if (!startDate || !state.location.startDate || !state.location.endDate) return state;

		let itemsToDisplay = 1,
			request = {
				locationsId: state.location.locationsId,
				// startDate: startDate,
				startDate: formatDate(state.location.startDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT),
				// startDate: "20240222",
				// endDate: startDate,
				endDate: formatDate(state.location.endDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT),
				// endDate: '20240225',
				satellite: '',
			},
			firstAnalysesIndex = 0;
		if (!request.startDate) {
			request.startDate = state.location.dateRange.start_date;
		}
		if (!request.endDate) {
			request.endDate = state.location.dateRange.end_date;
		}

		let imagesCopy, imageriesCopy;
		try {
			imagesCopy = isSameLocation ? state.images : {};
			imageriesCopy = isSameLocation ? state.location.imagery : [];
		} catch (e) {
			console.log(e);
		}

		log('displayImagery', from, 'hasState', !!state, 'for', itemsToDisplay, 'items: ', JSON.stringify(request), new Date().toLocaleTimeString());

		state.mode = Constants.MODES.View;
		setLoading(true);

		let index = 0;
		let imagery = '';
		if (state.location.imagery && state.location.imagery.length > 0) {
			index = state.location.imagery.findIndex((el) => el.date === startDate);
			imagery = [state.location.imagery[index]];
		} else {
			if (shouldLoadImageries) {
				if (state.location && state.location.satellites && state.location.satellites.S3 && state.location.satellites.S3.dates?.length > 0) {
					request.satellite = SATELLITES.copernicus;
					try {
						imagery = await fetch({
							url: `getImagery`,
							data: request,
						});
						state.copernicusImagery = imagery;
						state.location.imagery = imagery;
					} catch (e) {
						setLoading(false);
						setModalOpened(true);
						setModalProps({
							open: true,
							closeModal: async () => {
								await setModalOpened(false);
								setOpenAoiList(true);
							},
							modalTitle: 'The service is not responding', //The service is not responding, please try later.
							type: ERROR_MODAL,
							actionButtonClick: exportDataRanges,
							actionButtonTitle: 'Close',
							closeActionButtonTitle: 'Close',
							setModalOpened: setModalOpened,
							location: appState.location,
							errorMessage: 'Please visit back later, Data currently not available.',
						});
					}
				}
				if (state.location && state.location.satellites && state.location.satellites.PSD && state.location.satellites.PSD.dates?.length > 0) {
					request.satellite = SATELLITES.planet;
					imagery = await fetch({
						url: `getImagery`,
						data: request,
					});
					state.planetImagery = imagery;
				}
			}

			// if(satelliteNumber === 2){
			// 	request.satellite = planet;
			// 	imagery = await fetch({
			// 		url: `getImagery`,
			// 		data: request,
			// 	});
			// 	state.location.imagery = imagery;

			// }
		}

		if (!isBlank(imagery)) {
			log(`displayImagery received ${imagery.length} items`);

			if (state.location.hasImagery) {
				state = resetImagery(false, state);
				state.lastCachedDate = undefined;
				state.images = {};
				state.hasImageryDetails = true;
			}

			// state.firstCachedDate = imagery[0].date; original
			state.firstCachedDate = state.location.satellites['S3'].dates[state.location.satellites['S3'].dates.length - 1];
			state.location.hasImagery = true;

			// while (firstAnalysesIndex < state.location.imagery.length && !state.location.imagery[firstAnalysesIndex][satelliteView].analysesId && !state.location.imagery[firstAnalysesIndex][satelliteView].planetAnalysesId) {
			// 	firstAnalysesIndex++;
			// }

			// if (firstAnalysesIndex !== state.location.imagery.length) {
			// 	if (state.location.imagery[firstAnalysesIndex][satelliteView].analyses.analysesId) {
			// 		state.location.imagery[firstAnalysesIndex][satelliteView].analyses = await fetch({
			// 			url: `getAnalyses`,
			// 			data: {
			// 				analysesId: state.location.imagery[firstAnalysesIndex][satelliteView].analyses.analysesId,
			// 				satellite: satelliteView,
			// 			},
			// 		});
			// 		state.location.imagery[firstAnalysesIndex][satelliteView].analyses = state.location.imagery[firstAnalysesIndex][satelliteView].analyses[satelliteView]
			// 	}
			// }

			let validImageryItems = 0;

			for (let imageryIndex = 0; validImageryItems < itemsToDisplay && imageryIndex < state.location.imagery.length; imageryIndex++) {
				state.location.imagery = state.copernicusImagery; // so we always get analyses for S3
				// let nextIndex = imageryIndex + 1;

				if (imageryIndex === firstAnalysesIndex) {
					firstAnalysesIndex = 0;
				}

				if (state.location.imagery[index]) {
					if (firstAnalysesIndex === 0 && index !== state.location.imagery.length && state.location.imagery[index][SATELLITES.copernicus].analyses.analysesId) {
						state.location.imagery[index][SATELLITES.copernicus].analyses = await fetch({
							url: `getAnalyses`,
							data: {
								analysesId: state.location.imagery[index][SATELLITES.copernicus].analyses.analysesId,
								// satellite: satelliteView,
								satellite: 'copernicus',
							},
						});
						state.location.imagery[index][SATELLITES.copernicus].analyses = state.location.imagery[index][SATELLITES.copernicus].analyses[SATELLITES.copernicus];
					}

					if (!isBlank(state.location.imagery[index][SATELLITES.copernicus].analyses) || !isBlank(state.location.imagery[index]?.planet?.analyses)) {
						if (!isBlank(state.location.imagery[index][SATELLITES.copernicus].analyses)) {
							if (state.location.imagery[index][SATELLITES.copernicus].status !== 'missing') {
								state.location.imagery[index][SATELLITES.copernicus].analyses.data = LZString.decompressFromBase64(state.location.imagery[index][SATELLITES.copernicus].analyses.data)
									.split('!')
									.map((_prop) => _prop.split('/').map((__prop, _i) => (_i !== 3 ? Number(__prop) : __prop)));
								state.location.imagery[index] = await processAnalyses(state.location.imagery[index], state);
							}
						}

						// if(satelliteView === SATELLITES.copernicus){
						state = await createThumbnails(state.location.imagery[index], null, state);
						// }
						state.lastCachedDate = state.location.imagery[index].date;
						validImageryItems++;
					} else {
						log('displayImagery::loop::noAnalyses', state.location.imagery[index]?.date);
						if (state.location.imagery[index][SATELLITES.copernicus].status !== 'missing') {
							state.location.imagery[index][SATELLITES.copernicus].geoJSON = {
								type: 'FeatureCollection',
								features: [],
							};
							state.location.imagery[index].status = 'missing';
							// if(satelliteView === SATELLITES.copernicus){
							state = await createThumbnails(state.location.imagery[index], null, state);
							// }
							state.lastCachedDate = state.location.imagery[index].date;
							validImageryItems++;
						}
					}
				}
			}

			if (state.location.imagery && state.location.imagery[0] && state.location.imagery[itemsToDisplay - 1]) {
				state.firstDateDisplayed = state.location.imagery[0].date;
				state.lastDateDisplayed = state.location.imagery[itemsToDisplay - 1].date; //state.location.imagery[state.location.imagery.length - 1].date;
			}
			state.imageryDate = state.location.imagery[index] ? state.location.imagery[index].date : state.imageryDate;
		} else if (!state.location.hasImagery && state.location.satellites && state.location.satellites['S3'] && state.location.satellites['S3'].dates?.length === 0) {
			let buttons = [
					{
						label: state.userCanNavigate && state.locations.length > 1 ? 'Close' : 'Retry',
						onClick: async () => {
							if (!state.location.hasImagery) {
								state = state.userCanNavigate && state.locations.length > 1 ? await resetDisplayedLocation(false) : await updateMapLocation('displayImageryDetails::noCachedData::retry');
							} else {
								state = await setStartMonthFilter(state.prevEndDate, true);
							}
						},
					},
				],
				message = state.location.hasImagery ? 'There is no imagery available for the selected month.' : 'Imagery is currently being prepared for this location. Please try again later';

			if (!state.location.hasImagery && state.userIsAdmin) {
				buttons.push({
					label: 'Delete Location',
					onClick: async () => {
						state = await deleteLocation(state, confirmAlert, resetDisplayedLocation);
						// await updateState(state, 'displayImageryDetails::noCachedData::delete');
						resetDisplayedLocation(false);
					},
				});

				message += ', or select Delete Location if you want to remove this location.';
			} else {
				message += '.';
			}

			confirmAlert({
				title: state.location.name,
				message: message,
				closeOnEscape: false,
				closeOnClickOutside: false,
				buttons: buttons,
			});
		}

		if (state.lastCachedDate && !state.hasTrendDataForNavigator) {
			state = await getTrendData(state);
			//
		}

		if (!abortLoading && !loadingAborted && state.location?.imagery?.length > itemsToDisplay) {
		} else {
			loadingAborted = false;
			abortLoading = false;
		}
		setLoading(false);
		if (imagesCopy && state.images && imageriesCopy && state.location.imagery) {
			state.images = { ...imagesCopy, ...state.images };
			state.location.imagery = [...state.location.imagery, ...imageriesCopy];
		}

		return state;
	};

	const getTrendData = async (state = appState) => {
		const copy = { ...state };
		copy.trendData = getTrendsDataFromImageries(copy);
		copy.trendDataForAllSatellites = copy.trendData;
		copy.trendData = copy.trendData ? copy.trendData[satelliteView] : [];

		// copy.trendData = await fetch({
		// 	url: `getTrendData`,
		// 	data: {
		// 		locationsId: copy.location.locationsId,
		// 	},
		// }).then((_trendData) => {
		// 	return _trendData[SATELLITES.copernicus].map((_trend, _index) => ({
		// 		..._trend,
		// 		date: offset <= 0 ? subHours(new Date(_trend.date), offset) : addHours(new Date(_trend.date), Math.abs(offset)),
		// 		Carbon: _trend.carbon ? Number(_trend.carbon.toFixed(2)) : _trend.BloomSpread > 0.5 ? Number((((_trend.BloomLevel * state.location.polygonArea) / 10) * (state.location.depth || Constants.DEFAULT_AOI_DEPTH) * Constants.CARBON_FACTOR).toFixed(2)) : 0,
		// 	}));
		// });

		copy.hasTrendDataForNavigator = copy.trendData.length > 0;
		// state = await updateState(state, 'getTrendData::trends');
		return copy;
	};

	// const getPlanetImage = async (stateCopy) => {
	// 	const state = stateCopy;

	// 	if (state.imagery && state.imagery.urls.planet_url && state.imagery.urls.planet_url.startsWith('https')) {
	// 		state.imagery.planetTimestamp = state.imagery.urls.planet_url.split('/').pop().split('_')[1];

	// 		const fetchImage = async (url) => {
	// 			let image = await fetch({
	// 				baseURL: url,
	// 				method: 'GET',
	// 				mode: 'cors',
	// 				headers: { Accept: 'image/webp', 'Access-Control-Allow-Origin': '*' },
	// 				responseType: 'arraybuffer',
	// 			});

	// 			if (image.Error) {
	// 				image = Constants.BLANK;
	// 			}

	// 			return image;
	// 		};

	// 		state.imagery.urls.planet_url = await fetchImage(state.imagery.urls.planet_url);
	// 		updateState(state, 'getPlanetImage');
	// 	}

	// 	return state;
	// };

	// Layer management methods

	// const showLayerManager = async (hasNextSelectedNavigatorItem, date) => {
	// 	let state = { ...appState },
	// 		hasLayers = state.imageryLayers.length > 0,
	// 		selectedNavigatorItem = state.doc.querySelector('.imageryBlock .selected');
	// 	// hasNextSelectedNavigatorItem = selectedNavigatorItem?.parentElement?.id !== target.id;

	// 	// identify layers that are already present on the map
	// 	const hasTrueColour = state.imageryLayers.find((_layer) => _layer.layerMode === 'trueColour') && state.images[state.imageryDate].thumbnails.trueColour,
	// 		hasBGI = state.imageryLayers.find((_layer) => _layer.layerMode === 'bgi') && state.images[state.imageryDate].thumbnails.bgi,
	// 		hasBloom = state.imageryLayers.find((_layer) => _layer.layerMode === 'bloom') && state.images[state.imageryDate].thumbnails.bloom,
	// 		hasPlanet = state.imageryLayers.find((_layer) => _layer.layerMode === 'planetTrueColour');

	// 	state.imageryDate = date;

	// 	state.imagery = state.location.imagery.find((_imagery) => _imagery.date === date);

	// 	// this will be set to the select imagery item - there should ever only be one or none
	// 	// if this is none then this is the first time it has been clicked, otherwise an item has been previously clicked
	// 	if (selectedNavigatorItem) {
	// 		// if the selected item is not the same as the one that has been clicked
	// 		if (hasNextSelectedNavigatorItem) {
	// 			// get the layer(s) of the currently selected item
	// 			// unselect the currently selected item
	// 			// selectedNavigatorItem.classList.remove("selected");
	// 		} else {
	// 			// this will remove all layers
	// 			// state = await closeLayerManager(state);
	// 		}
	// 	}

	// 	// if the selected item is not the same as the one that has been clicked and there were layers displayed
	// 	if (hasNextSelectedNavigatorItem && hasLayers) {
	// 		if (!isBlank(state.imageryLayers)) {
	// 			[...state.imageryLayers].forEach(async (_layer) => {
	// 				state = await removeLayer(_layer, state);
	// 			});
	// 		}

	// 		if (hasPlanet) {
	// 			if (state.imagery.urls.planet_url && state.imagery.urls.planet_url !== Constants.BLANK && selectedLayers.includes('planet')) {
	// 				await showPlanet(
	// 					{
	// 						target: state.doc.querySelector(`.buttonImagery_planet`),
	// 					},
	// 					state.imagery
	// 				);
	// 			} else if (selectedLayers.includes('planet')) {
	// 				await setSelectedLayers(selectedLayers.filter((el) => el !== 'planet'));
	// 			}
	// 		}

	// 		if (hasTrueColour) {
	// 			await addLayer(state.imagery.imageryId, 'trueColour', state);
	// 			// state.doc.querySelector(`.buttonImagery_trueColour`).classList.add('selected');
	// 		}

	// 		if (hasBGI || hasBloom) {
	// 			state.layerPopup.active = false;
	// 		}

	// 		if (hasBGI) {
	// 			await addLayer(state.imagery.imageryId, 'bgi', state);
	// 			// state.legendControl.show(`bgi`);
	// 		}

	// 		if (hasBloom && state.images[state.imageryDate].thumbnails.bloom !== Constants.BLANK) {
	// 			await addLayer(state.imagery.imageryId, 'bloom', state);
	// 			// state.legendControl.show(`bloomLegend`);
	// 			state.doc.querySelector('.buttonPitch') && state.doc.querySelector('.buttonPitch').classList.remove('hidden');
	// 		} else if (hasBloom && state.images[state.imageryDate].thumbnails.bloom === Constants.BLANK && state.is3D) {
	// 			await togglePitch();
	// 		}
	// 	}

	// 	if (hasNextSelectedNavigatorItem) {
	// 		state.hasImageryDetails = true;
	// 	}

	// 	if (state.images[date].imagery.urls.planet_url && state.images[date].imagery.urls.planet_url.startsWith('https') && !hasPlanet) {
	// 		state = await getPlanetImage(state);
	// 	}

	// 	await updateState(state, `showLayerManager ${date}`);
	// };

	// const closeLayerManager = async () => {
	// 	let state = appState;
	// 	if (state.imagery) {
	// 		state.doc.querySelector(`.imageryBlock_${state.imagery.date} .imageButton`).classList.remove('selected');
	// 	}
	// 	setSelectedLayers([]);

	// 	[...state.imageryLayers].forEach(async (layer) => {
	// 		state = await removeLayer(layer, state);
	// 	});

	// 	state.map.resetNorthPitch();
	// 	//state.view360Control.hide();
	// 	state.is3D = false;
	// 	state.layerPopup.active = false;
	// 	state.hasImageryDetails = false;

	// 	// if (state.legendControl) {
	// 	// state.legendControl.hide('bgiLegend');
	// 	// }

	// 	await updateState(state, 'closeLayerManager');
	// 	return state;
	// };

	// const showTrueColour = async (imageryIdParam) => {
	// 	let state = { ...appState },
	// 		layer = selectedLayers.includes('trueColour') ? state.imageryLayers?.find((_layer) => _layer.imageryId === imageryIdParam && _layer.layerMode === 'trueColour') : undefined;
	// 	if (selectedLayers.includes('trueColour')) {
	// 		state = await removeLayer(layer, state);
	// 		setSelectedLayers(selectedLayers.filter((el) => el !== 'trueColour'));
	// 	} else {
	// 		state = await addLayer(imageryIdParam, 'trueColour', state);
	// 		setSelectedLayers([...selectedLayers, 'trueColour']);
	// 	}

	// 	await updateState(state, 'showTrueColour');
	// 	return state;
	// };

	const showLayerNew = async (layerMode) => {
		let currentImagery = '';
		let modifiedDate = format(selectedDate['$d'], Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
		modifiedDate = dayjs(modifiedDate);

		let newDate = modifiedDate['$d'];

		if (newDate && appState.images) {
			let formattedDate = format(newDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT);

			if (appState.images[formattedDate] && appState.images[formattedDate].imagery) {
				currentImagery = appState.images[formattedDate].imagery;
				if (satelliteView && satelliteView === SATELLITES.planet) {
					return showPlanetService(currentImagery.imageryId, appState, selectedLayers, setSelectedLayers, removeLayer, satelliteView, layerMode, currentImagery.date, updateState);
				} else if (satelliteView && satelliteView === SATELLITES.copernicus) {
					return showLayer(appState, selectedLayers, currentImagery.imageryId, layerMode, setSelectedLayers, createLayerHoverPopup, updateState, setPaintPropertiesNew, satelliteView);
				}
			} else {
				//we have no imagery -> unselect selected layer
				if (selectedLayers.indexOf(layerMode) >= 0) {
					let filteredLayers = selectedLayers.filter((el) => el !== layerMode);
					setSelectedLayers(filteredLayers);
				} else {
					setSelectedLayers([...selectedLayers, layerMode]);
				}
			}
		}
	};

	const getCurrentImagery = () => {
		if (!selectedDate || !selectedDate['$d']) return '';
		let newDate = selectedDate['$d'];
		let formattedDate = format(newDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
		if (!appState || !appState.images || appState.images.length <= 0 || !formatDate) {
			return '';
		}

		if (appState.images[formattedDate] && appState.images[formattedDate].imagery) {
			return appState.images[formattedDate].imagery;
		}
		return '';
	};

	const showLayersAfterDateChange = async (newDate, view = '', stateCopy) => {
		let satelliteCopy = view ? view : satelliteView;
		if (selectedLayers.length > 0) {
			let state = stateCopy ? { ...stateCopy } : { ...appState };
			state = await removeAllLayers();
			let currentImagery = '';
			if (stateCopy && stateCopy.images) {
				state.images = stateCopy.images;
			}
			if (newDate && state.images) {
				let formattedDate = format(newDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT);

				if (state.images[formattedDate] && state.images[formattedDate].imagery) {
					currentImagery = state.images[formattedDate].imagery;
					// imageryIdParam = currentImagery.imageryId;
				}
			}

			let copyArray = [];
			selectedLayers.map(async (el) => {
				if (satelliteCopy === SATELLITES.planet) {
					if (el === PLANET_TRUE_COLOR || el === PLANET_BGI || el === PLANET_BLOOM || el === PLANET_CLOUDS) {
						await showPlanetService(currentImagery.imageryId, state, selectedLayers, setSelectedLayers, removeLayer, satelliteCopy, el, currentImagery.date, updateState);
					}
				} else {
					if (el === PLANET_TRUE_COLOR || el === PLANET_BGI || el === PLANET_BLOOM || el === PLANET_CLOUDS) {
					} else {
						await showLayer(state, copyArray, currentImagery.imageryId, el, setSelectedLayers, createLayerHoverPopup, updateState, setPaintPropertiesNew, satelliteCopy);
					}
				}
				copyArray = [...copyArray, el];
				return el;
			});
			setSelectedLayers(selectedLayers);
		}
		setLoading(false);
	};

	// const showBGI = async (imageryIdParam) => {
	// 	let state = appState;

	// 	if (selectedLayers.includes('bgi')) {
	// 		let layer = selectedLayers.includes('bgi') ? state.imageryLayers.find((_layer) => _layer.imageryId === imageryIdParam && _layer.layerMode === 'bgi') : undefined;
	// 		state = await removeLayer(layer, state);
	// 		state.legendControl.hide('bgiLegend');
	// 		setSelectedLayers(selectedLayers.filter((el) => el !== 'bgi'));
	// 	} else {
	// 		state = await addLayer(imageryIdParam, 'bgi', state);
	// 		setSelectedLayers([...selectedLayers, 'bgi']);
	// 		state.legendControl.show('bgiLegend');
	// 	}

	// 	await updateState(state, 'showBGI');
	// 	return state;
	// };

	// const showBloom = async (imageryIdParam) => {
	// 	let state = appState,
	// 		layer = selectedLayers.includes('bloom') ? state.imageryLayers.find((_layer) => _layer.imageryId === imageryIdParam && _layer.layerMode === 'bloom') : undefined;
	// 	if (selectedLayers.includes('bloom')) {
	// 		state = await removeLayer(layer, state);
	// 		setSelectedLayers(selectedLayers.filter((el) => el !== 'bloom'));
	// 		state.legendControl.hide('bloomLegend');
	// 		state.doc.querySelector('.buttonPitch').classList.add('hidden');
	// 	} else {
	// 		let layersCopy = [...selectedLayers];
	// 		if (!state.map.getLayer(`layer_bgi_${imageryIdParam}`)) {
	// 			state = await addLayer(imageryIdParam, 'bgi', state);
	// 			if (selectedLayers.includes('bgi')) {
	// 			} else {
	// 				layersCopy = [...layersCopy, 'bgi'];
	// 			}
	// 			state.legendControl.show('bgiLegend');
	// 		}

	// 		state = await addLayer(imageryIdParam, 'bloom', state);
	// 		setSelectedLayers([...layersCopy, 'bloom']);
	// 		state.legendControl.show('bloomLegend');
	// 		state.doc.querySelector('.buttonPitch').classList.remove('hidden');
	// 	}

	// 	await updateState(state, 'showBloom');
	// 	return state;
	// };

	// const showPlanet = async (event, imagery) => {
	// 	let state = appState,
	// 		target = event.target.nodeName !== 'TD' ? (event.target.parentElement.nodeName !== 'TD' ? (event.target.parentElement.parentElement.nodeName !== 'TD' ? event.target.parentElement.parentElement.parentElement : event.target.parentElement.parentElement) : event.target.parentElement) : event.target,
	// 		planetLayer = state.imageryLayers.find((_layer) => _layer.layerMode === 'planetTrueColour');

	// 	if (planetLayer) {
	// 		setSelectedLayers(selectedLayers.filter((el) => el !== 'planet'));
	// 		state = await removeLayer(planetLayer, state);
	// 	} else {
	// 		let boundingBox = bbox(state.location.geometry),
	// 			imageCoordinates = [
	// 				[boundingBox[0], boundingBox[3]],
	// 				[boundingBox[2], boundingBox[3]],
	// 				[boundingBox[2], boundingBox[1]],
	// 				[boundingBox[0], boundingBox[1]],
	// 			];

	// 		let selectedLayersCopy = [...selectedLayers];
	// 		// target.classList.add('selected');
	// 		if (!selectedLayers.includes('planet')) {
	// 			selectedLayersCopy = [...selectedLayers, 'planet'];
	// 		}

	// 		if (target.attributes['layer-source'] && target.attributes['layer-source'].value && target.attributes['layer-source'].value !== Constants.BLANK) {
	// 			await state.map.addSource('planet', {
	// 				type: 'image',
	// 				url: imagery?.urls?.planet_url ? imagery?.urls?.planet_url : target.attributes['layer-source'].value,
	// 				coordinates: imageCoordinates,
	// 			});

	// 			await state.map.addLayer({
	// 				id: 'planet',
	// 				type: 'raster',
	// 				source: 'planet',
	// 				paint: {
	// 					'raster-fade-duration': 0,
	// 				},
	// 			});
	// 			state.imageryLayers.push({
	// 				id: 'planet',
	// 				layerMode: 'planetTrueColour',
	// 				imageryId: imagery?.imageryId ? imagery.imageryId : state.images[state.imageryDate].imagery.imageryId,
	// 				layer: state.map.getLayer('planet'),
	// 			});
	// 		} else if (target.attributes['layer-source'].value !== Constants.BLANK) {
	// 			selectedLayersCopy = selectedLayersCopy.filter((el) => el !== 'planet');
	// 		}

	// 		let value = imagery?.urls?.planet_url ? imagery?.urls?.planet_url : target.attributes['layer-source'].value;

	// 		if (value !== Constants.BLANK) {
	// 			setSelectedLayers(selectedLayersCopy);
	// 		}
	// 	}
	// 	return state;
	// };

	const togglePitch = async (event, imageryId) => {
		if (event) {
			event.stopPropagation();
		}

		let state = appState;
		log('togglePitch', 'state.imageryLayers.length', state.imageryLayers.length);
		const layer = state.imageryLayers.find((_layer) => _layer.imageryId === imageryId && _layer.layerMode === 'bloom'),
			pitchend = async (layer) => {
				state.map.off('pitchend', pitchend);
				state.is3D = !state.is3D;

				if (event) {
					if (state.is3D) {
						// state.doc.querySelector('.buttonRotate').classList.remove('hidden');
					} else {
						state.map.resetNorthPitch();
						// state.doc.querySelector('.buttonRotate').classList.add('hidden');
					}

					await removeLayer(layer, state);
					if (layer && layer.imageryId) {
						if (state.is3D) {
							await addLayer(layer.imageryId, 'bloom', state);
						} else {
							showLayerNew('bloom');
						}
					}

					// await showLayerNew('bloom');
				}

				await updateState(state, 'togglePitch');
			};

		state.map.once('pitchend', () => pitchend(layer));
		state.map.easeTo({ pitch: !state.is3D ? 60 : 0, bearing: 0 });
	};

	const createLayerHoverPopup = (layerId, state = appState) => {
		const layerPopup = new mapboxgl.Popup(state.layerPopup);

		let hoverEventIn = 'mousemove';
		let hoverEventOut = 'mouseout';
		if (isTablet) {
			// add touch start and end events
			hoverEventIn = 'touchstart';
			hoverEventOut = 'touchend';
		}
		state.map.on(hoverEventIn, layerId, (event) => {
			const pointDetails = state.doc.querySelector('.mapPoint');
			let features = state.map.queryRenderedFeatures(event.point);
			let timerId = null;
			let condition;
			if (isTablet) {
				condition = features[0].properties.bgi > 0;
				const popups = state.doc.getElementsByClassName('mapboxgl-popup');
				if (popups && popups.length && popups[0]) {
					popups[0].remove();
				}
			} else {
				condition = features[0].properties.bgi > 0;
			}
			if (condition) {
				const lngLat = JSON.parse(features[0].properties.centroid);
				// Change the cursor style as a UI indicator.
				state.map.getCanvas().style.cursor = 'pointer';
				// Copy coordinates array.
				let coordinates = features[0].geometry.coordinates.slice();
				const table = state.doc.createElement('table'),
					tbody = state.doc.createElement('tbody');

				let tr = state.doc.createElement('tr'),
					td = state.doc.createElement('td');

				td.innerHTML = `<span style="font-weight: bold;">BGI:</span>&nbsp;${Constants.LEGENDS[satelliteView].bgi.find((_legend) => _legend.threshold === features[0].properties.bgi).label}`;
				tr.append(td);
				tbody.append(tr);

				// tr = state.doc.createElement('tr');
				// td = state.doc.createElement('td');
				// td.innerHTML = `<span style="font-weight: bold;">Classification:</span>&nbsp;${Constants.BGI_CLASSIFICATION[satelliteView][features[0].properties.classification]}`;
				// tr.append(td);
				// tbody.append(tr);

				if (features[0].properties.bgi === 5) {
					tr = state.doc.createElement('tr');
					td = state.doc.createElement('td');
					td.innerHTML = `<span style="font-weight: bold;">BG-Chl a:</span>&nbsp;${
						features[0].properties.bloom > 0 ? `${Number(features[0].properties.bloom * Constants.UNITS[state.user.units].BloomLevel.factor).toFixed(Constants.UNITS[state.user.units].BloomLevel.decimals)}${Constants.UNITS[state.user.units].BloomLevel.label}` : '--'
					}`;
					tr.append(td);
					tbody.append(tr);
				}

				tr = state.doc.createElement('tr');
				td = state.doc.createElement('td');
				td.innerHTML = `<span style="font-weight: bold;">Longitude:</span>&nbsp;${lngLat.lng.toFixed(5)}`;
				tr.append(td);
				tbody.append(tr);
				tr = state.doc.createElement('tr');
				td = state.doc.createElement('td');
				td.innerHTML = `<span style="font-weight: bold;">Latitude:</span>&nbsp;${lngLat.lat.toFixed(5)}`;
				tr.append(td);
				tbody.append(tr);
				tr = state.doc.createElement('tr');
				td = state.doc.createElement('td');
				td.innerHTML = `<span style="font-weight: bold;">Elevation:</span>&nbsp;${Number((state.map.queryTerrainElevation(lngLat, { exaggerated: false }) || 0) * Constants.UNITS[state.user.units].elevation.factor).toFixed(Constants.UNITS[state.user.units].elevation.decimals)}${
					Constants.UNITS[state.user.units].elevation.label
				} above sea level`;
				tr.append(td);
				tbody.append(tr);
				table.append(tbody);

				if (isTablet) {
					coordinates = event.features[0].geometry.coordinates.slice();
				}
				// Ensure that if the map is zoomed out such that multiple copies of the feature are visible, the popup appears over the copy being pointed to.
				while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
					coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
				}

				if (!pointDetails) {
					if (isTablet) {
						layerPopup.setLngLat(event.lngLat).setDOMContent(table).addTo(state.map);
						const el = state.doc.querySelector('.mapboxgl-popup');

						if (el) {
							el.classList.add('tablet-lake-popup');
							clearTimeout(timerId);
							timerId = setTimeout(() => {
								layerPopup.remove();
							}, 5000);
						}
					} else {
						layerPopup.trackPointer().setDOMContent(table).addTo(state.map);
					}
				} else {
					if (isTablet) {
						layerPopup.setLngLat(event.lngLat).setDOMContent(table).addTo(state.map);
						const el = state.doc.querySelector('.mapboxgl-popup');

						if (el) {
							el.classList.add('tablet-lake-popup');
						}

						clearTimeout(timerId);
						timerId = setTimeout(() => {
							layerPopup.remove();
						}, 5000);
					} else {
						layerPopup.setDOMContent(table);
					}
					pointDetails.classList.remove('hidden');
				}
			}
		});

		state.map.on(hoverEventOut, layerId, (event) => {
			const pointDetails = state.doc.querySelector('.mapPoint');

			if (isTablet) {
			} else {
				if (pointDetails) {
					pointDetails.classList.add('hidden');
					state.map.getCanvas().style.cursor = '';
					layerPopup.remove();
				}
			}
		});

		state.layerPopup.active = true;
		return state;
	};

	const setPaintProperties = (layerId, layerType, layerMode, state = appState) => {
		const setFillColour = () => {
			if (layerMode === 'trueColour') {
				state.fillColour = ['get', 'color'];
			} else {
				state.fillColour = ['case'];

				if (layerMode !== 'bloom') {
					Constants.LEGENDS[satelliteView][layerMode].forEach((element) => {
						state.fillColour.push(['==', ['get', layerMode], element.threshold]);
						state.fillColour.push(element.colour.toString());
					});
				} else {
					Constants.LEGENDS[satelliteView][layerMode].forEach((element) => {
						state.fillColour.push(['>=', ['get', layerMode], element.threshold]);
						state.fillColour.push(element.colour.toString());
					});
				}

				state.fillColour.push('transparent');
			}

			return state.fillColour;
		};

		switch (layerType) {
			case 'fill-extrusion':
				state.map.setPaintProperty(layerId, 'fill-extrusion-base', 0);
				state.map.setPaintProperty(layerId, 'fill-extrusion-height', ['*', 5, ['get', layerMode]]);
				state.map.setPaintProperty(layerId, 'fill-extrusion-color', setFillColour());
				state.map.setPaintProperty(layerId, 'fill-extrusion-opacity', 1);
				break;

			case 'fill':
				state.map.setPaintProperty(layerId, 'fill-color', setFillColour());
				state.map.setPaintProperty(layerId, 'fill-opacity', 1);
				state.map.setPaintProperty(layerId, 'fill-antialias', true);
				break;

			default:
				break;
		}

		return state;
	};

	const setPaintPropertiesNew = (layerId, layerType, layerMode, state = appState) => {
		let legends = getLegends(isProUser);
		legends = legends[satelliteView];
		const setFillColour = () => {
			if (layerMode === TRUECOLOR) {
				state.fillColour = ['get', 'color'];
			} else {
				if (layerMode === CLOUDS || layerMode === INTERFERENCES || layerMode === PLANET_BGI) {
					layerMode = 'bgi';
				}
				state.fillColour = ['case'];

				if (layerMode !== BLOOM && layerMode !== PLANET_BLOOM) {
					legends[layerMode].forEach((element) => {
						state.fillColour.push(['==', ['get', layerMode], element.threshold]);
						state.fillColour.push(element.colour.toString());
					});
				} else {
					legends[layerMode].forEach((element) => {
						state.fillColour.push(['>=', ['get', layerMode], element.threshold]);
						state.fillColour.push(element.colour.toString());
					});
				}

				state.fillColour.push('transparent');
			}

			return state.fillColour;
		};

		switch (layerType) {
			case 'fill-extrusion':
				state.map.setPaintProperty(layerId, 'fill-extrusion-base', 0);
				state.map.setPaintProperty(layerId, 'fill-extrusion-height', ['*', 5, ['get', layerMode]]);
				state.map.setPaintProperty(layerId, 'fill-extrusion-color', setFillColour());
				state.map.setPaintProperty(layerId, 'fill-extrusion-opacity', 1);
				break;

			case 'fill':
				state.map.setPaintProperty(layerId, 'fill-color', setFillColour());
				state.map.setPaintProperty(layerId, 'fill-opacity', 1);
				state.map.setPaintProperty(layerId, 'fill-antialias', true);
				break;

			default:
				break;
		}

		return state;
	};

	const createLayer = async (layerId, layerMode, state = appState) => {
		log('createLayer', 'layerId', layerId, 'layerMode', layerMode, 'is3D', state.is3D === true);

		if (state.map && !state.map.getLayer(layerId)) {
			let layerType = layerMode === 'bloom' && state.is3D ? 'fill-extrusion' : 'fill',
				features = [...state.images[state.imageryDate].imagery.geoJSON.features].filter((_feature) => (layerMode === 'bloom' && _feature.properties.bloom > 0) || (_feature.properties.bgi !== 0 && _.includes(['bgi', 'trueColour'], layerMode))),
				latitudeDifference = Constants.SEN3_CELL_RADIUS / 2 / Constants.EARTH_CIRCUMFERENCE,
				planetLayer = state.imageryLayers.find((_layer) => _layer.layerMode === 'planetTrueColour');

			if (planetLayer) {
				state = await removeLayer(planetLayer, state);
			}

			for (let featureIndex = 0; featureIndex < features.length; featureIndex++) {
				features[featureIndex] = mb_buffer(features[featureIndex], latitudeDifference, { units: 'degrees' });
				let boundingBox = bbox(features[featureIndex].geometry);

				//if(layerMode === 'trueColour') {
				// square polygons present as fully joined horizontally and vertically
				features[featureIndex].geometry = bboxPolygon(square(boundingBox)).geometry;
				/*}
									else {
										// hexagon polygons using cell radius / 3 presents as unjoined hexagons
										//features[featureIndex].geometry = hexGrid(boundingBox, Constants.SEN3_CELL_RADIUS / 3 / 1000, { units: 'kilometers' }).features[0].geometry;

										// hexagon polygons using cell radius / 2.25 presents as horizontally joined hexagons
										features[featureIndex].geometry = hexGrid(boundingBox, Constants.SEN3_CELL_RADIUS / 2.25 / 1000, { units: 'kilometers' }).features[0].geometry;
									}*/
			}

			if (!state.map.getSource(layerId)) {
				state.map
					.addSource(layerId, {
						type: 'geojson',
						data: {
							...{
								type: 'FeatureCollection',
								features: features,
							},
							features: features,
						},
					})
					.addLayer({
						id: layerId,
						type: layerType,
						source: layerId,
						layout: {},
					});
			}

			state = setPaintProperties(layerId, layerType, layerMode, state);

			let blendedFeatures = features;

			if (features.length < 50000) {
				features.forEach((feature) => {
					const { row, column, color } = feature.properties;

					const adjacentFeatures = features.filter((adjacentFeature) => {
						const { adjacentRow, adjacentColumn } = adjacentFeature.properties;
						return (adjacentRow === row && Math.abs(adjacentColumn - column) === 1) || (adjacentColumn === column && Math.abs(adjacentRow - row) === 1);
					});

					let blendedColor = chroma(color);

					adjacentFeatures.forEach((adjacentFeature) => {
						const { color: adjacentColor } = adjacentFeature.properties;
						blendedColor = blendedColor.blend(chroma(adjacentColor), 0.5);
					});

					blendedFeatures.push({
						...feature,
						properties: {
							...feature.properties,
							color: blendedColor.hex(),
						},
					});
				});
			}

			state.map.getSource(layerId).setData({
				type: 'FeatureCollection',
				features: features,
			});

			if (layerMode === 'trueColour') {
				if (state.imageryLayers.length > 0) {
					state.map.moveLayer(layerId, state.imageryLayers[0].id);
				}

				state.imageryLayers.unshift({
					id: layerId,
					layerMode: layerMode,
					imageryId: state.images[state.imageryDate].imagery.imageryId,
					layer: state.map.getLayer(layerId),
				});
			} else if (layerMode === 'bgi' && state.imageryLayers.length > 0) {
				// state.map.moveLayer(layerId, state.imageryLayers[0].id);
				if (state.imageryLayers[0].layerMode === 'trueColour') {
					state.imageryLayers.splice(1, 0, {
						id: layerId,
						layerMode: layerMode,
						imageryId: state.images[state.imageryDate].imagery.imageryId,
						layer: state.map.getLayer(layerId),
					});

					if (state.imageryLayers[2] && state.imageryLayers[2].layerMode === 'bloom') {
						state.map.moveLayer(layerId, state.imageryLayers[2].id);
					} else {
						state.map.moveLayer(layerId, state.imageryLayers[1].id);
					}
				} else {
					//this case of 1 bloom layer
					state.imageryLayers.unshift({
						id: layerId,
						layerMode: layerMode,
						imageryId: state.images[state.imageryDate].imagery.imageryId,
						layer: state.map.getLayer(layerId),
					});
					state.map.moveLayer(layerId, state.imageryLayers[1].id);
				}
			} else {
				state.imageryLayers.push({
					id: layerId,
					layerMode: layerMode,
					imageryId: state.images[state.imageryDate].imagery.imageryId,
					layer: state.map.getLayer(layerId),
				});
			}

			if (!state.layerPopup.active && ((state.imageryLayers.length === 1 && !_.includes(['trueColour', 'planetTrueColour'], state.imageryLayers[0].layerMode)) || state.imageryLayers.length > 1)) {
				state = createLayerHoverPopup(state.imageryLayers[state.imageryLayers.length - 1].id, state);
			}

			await updateState(state, 'createLayer');
		} else if (!state.map) {
			log('createLayer', 'state.map is undefined!');
		}

		return state;
	};

	const removeLayer = async (layer, state = appState) => {
		// const isATrueColourLayer = layer && layer.layerMode === 'trueColour' || layer && layer.layerMode === 'planetTrueColour';
		// const isATrueColourLayer = selectedLayers.includes('trueColour') || selectedLayers.includes('planetTrueColour');

		// log('removeLayer', layer.id);

		if (!layer) return;
		if (state.map.getLayer(layer.id)) {
			state.map.removeLayer(layer.id).removeSource(layer.id);

			state.imageryLayers = state.imageryLayers.splice(
				state.imageryLayers.findIndex((_layer) => _layer.id === layer.id),
				1
			);

			state.map.off('mousemove', layer.id);
			state.map.off('mouseout', layer.id);

			if (state.imageryLayers.some((_layer) => _.includes([INTERFERENCES, BLOOM], _layer.layerMode))) {
				state = createLayerHoverPopup(state.imageryLayers[state.imageryLayers.length - 1].id, state);
			}

			await updateState(state, 'removeLayer');
		}

		return state;
	};

	const addLayer = async (imageryId, layerMode, state = appState) => {
		let imagery = state.location.imagery.find((_imagery) => _imagery.imageryId === imageryId),
			layerId = layerMode === 'trueColour' ? `layer_${imagery.imageryId}` : layerMode === 'bgi' ? `layer_${layerMode}_${imagery.imageryId}` : `layer_${layerMode}${state.is3D ? '3D' : '2D'}_${imagery.imageryId}`;

		state.layerLoading = true;
		await updateState(state, 'addLayer');
		state.geoJSON = imagery.geoJSON;
		state = await createLayer(layerId, layerMode, state);
		state.layerLoading = false;
		state = await updateState(state, 'addLayer');
		return state;
	};

	const rotate = async (event) => {
		//dont delete
		let state = appState;
		state.view360Control.rotate(event);
		// state.doc.querySelector('.buttonRotate').classList.toggle('active');
	};

	// Polygon Drawing Methods
	const drawSelectionChanged = async (event) => {
		let state = appState,
			updateDraw = true;

		state.map.off('draw.selectionchange', drawSelectionChanged);

		if (event.features.length > 0) {
			state.selectedGeometry = event.features[0];
			setcloseLocationPopupClicked(false);
		}

		switch (state.mode) {
			case Constants.MODES.None:
			case Constants.MODES.Add:
				if (showCreateLocationRef.current && event && event.features && event.features.length > 0) {
					updateDrawMode();
					state.mode = Constants.MODES.Add;
					state.locationIndex = undefined;
					await addLocation('state.mode===None', state);
					showCreateLocationRef.current = false;
				} else {
					// state.draw.changeMode("simple_select");
					await removeAllLayers();
					resizeMap(state);
				}

				break;

			// case Constants.MODES.Add:
			// updateDrawMode();
			// state.mode = Constants.MODES.View;

			// break;

			case Constants.MODES.Closing:
				updateDrawMode();
				state.mode = Constants.MODES.Closed;
				setcloseLocationPopupClicked(true);
				break;

			case Constants.MODES.Closed:
				updateDrawMode();
				state.mode = Constants.MODES.None;
				setcloseLocationPopupClicked(true);
				break;

			case Constants.MODES.View:
				updateDrawMode();
				updateDraw = false;
				break;

			case Constants.MODES.Edit:
				state.draw.changeMode('draw_polygon');
				state.hudControl.hide();

				break;

			case Constants.MODES.Loading:
				state.draw.changeMode('draw_polygon');
				break;

			default:
				break;
		}

		if (updateDraw) {
			await updateState(state, 'drawSlectionChanged');
		}

		if (state.userCanEdit) {
			state.map.on('draw.selectionchange', drawSelectionChanged);
			if (updateDraw) {
				await updateState(state, 'drawSlectionChanged');
			}
		}

		return state;
	};

	// const drawModeChanged = async (event) => {
	// 	let state = appState;
	// 	state.map.off('draw.handlemodechange', drawModeChanged);
	// 	//log(`drawModeChanged::mode changed to ${event.mode}`);

	// 	if (event.mode === 'draw_polygon') {
	// 		state.mode = Constants.MODES.None;
	// 		// state.AOILibraryControl.hide();
	// 	}

	// 	state.map.on('draw.handlemodechange', drawModeChanged);
	// 	await updateState(state, 'drawModeChanged');
	// 	return state;
	// };

	// Mapbox related methods

	// handle mouse move events
	// called from state.map.on('mousemove', mouseMove) and state.map.on('move', mouseMove)
	const updateTelemetryDisplay = async (event) => {
		let state = appState;
		const centre = event && event.lngLat ? event.lngLat : state.map.getCenter();
		state.coordinatesControl.updateCoordinates(centre);
		await updateState(state, 'updateTelemetryDisplay');
		return state;
	};

	const updateDrawMode = () => {
		if (!appState.user) return;
		if ((appState.user.groups && !_.includes(appState.user.groups, 'expert')) || isTablet) {
			appState.draw.changeMode('static');
		}
	};

	const editDrawMode = async (event) => {
		if (event) {
			let state = { ...appState };
			if (event.mode === 'draw_polygon') {
				state.hudControl.update(`Draw a polygon to select an area. Click to add a point. Click first point again to finish.`);
				state.hudControl.show();
				showCreateLocationRef.current = true;
			}
			if (event.mode === 'simple_select' && !showCreateLocationRef.current) {
				state.hudControl.update(``);
				state.hudControl.hide();
				// state.mode = Constants.MODES.Closed;
				// closeLocationPopupClicked
				setcloseLocationPopupClicked(true);
			}

			state = await removeAllLayers();
			resizeMap(state);

			if (showCreateLocationRef.current && event.mode === 'simple_select') {
				updateDrawMode();
				// state.mode = Constants.MODES.Add;
				state.locationIndex = undefined;
				if (state.selectedGeometry && state.selectedGeometry.geometry) {
					await addLocation('state.mode===None', state);
					showCreateLocationRef.current = false;
				} else {
					if (state.userCanEdit) {
						state.map.on('draw.selectionchange', drawSelectionChanged);
					}
				}
			}

			await updateState(state, 'editDrawMode');
		}
	};

	const createMapBoxComponents = async (state = appState) => {
		const createMapboxPolygons = () => {
				const features = state.locations.map((location, index) => {
						location.geometry.properties.class_id = index;
						location.geometry.properties.image = '';
						return location.geometry;
					}),
					geoJSON = {
						type: 'FeatureCollection',
						features: features,
					},
					polygons = state.draw.add(geoJSON);

				polygons.forEach((featureId, featureIndex) => {
					state.locations[featureIndex].featureId = featureId;
				});

				if (state.userCanEdit) {
					//to delete???
					// state.map.on('draw.selectionchange', drawSelectionChanged);
					// state.map.on('draw.modechange', drawModeChanged);
				}
			},
			coordinatesFeature = (lng, lat) => ({
				center: [lng, lat],
				geometry: {
					type: 'Point',
					coordinates: [lng, lat],
				},
				place_name: `Lat: ${lat} Long: ${lng}`,
				place_type: ['coordinate'],
				properties: {},
				type: 'Feature',
			}),
			coordinatesGeocoder = (query) => {
				let response,
					matchLatLng = /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Long: )?(-?\d+\.?\d*)[ ]*$/i,
					coordinates = [],
					geocodes = [];

				if (query.match(matchLatLng)) {
					query = query.replace(' ', '');

					if (query.indexOf(' ') >= 0) {
						coordinates = query.split(' ');
					} else if (query.indexOf(',') >= 0) {
						coordinates = query.split(',');
					}

					if (coordinates[0] < -90 || coordinates[0] > 90) {
						// must be lng, lat
						geocodes.push(coordinatesFeature(coordinates[0], coordinates[1]));
					}

					if (coordinates[1] < -90 || coordinates[1] > 90) {
						// must be lat, lng
						geocodes.push(coordinatesFeature(coordinates[1], coordinates[0]));
					}

					if (geocodes.length === 0) {
						// otherwise, could be either lng, lat or lat, lng
						geocodes.push(coordinatesFeature(coordinates[0], coordinates[1]));
						geocodes.push(coordinatesFeature(coordinates[1], coordinates[0]));
					}

					response = geocodes;
				} else {
					response = null;
				}

				return response;
			};

		if (state.doc.querySelector('.map') && state.doc.querySelector('.loading')) {
			state.doc.querySelector('.map').removeChild(state.doc.querySelector('.loading'));
		}

		state.map.setFog({
			range: [0.8, 8],
			color: 'rgba(85, 151, 210, 0.5)',
			'horizon-blend': 0.05,
			'high-color': '#245bde',
			'space-color': '#000000',
			'star-intensity': 0.75,
		});

		state.map.resize();

		if (!state.map.getLayer('sky')) {
			state.map.addLayer({
				id: 'sky',
				type: 'sky',
				paint: {
					'sky-type': 'atmosphere',
					'sky-atmosphere-color': 'rgba(85, 151, 210, 0.5)',
				},
			});
		}

		if (!state.map.getSource('mapbox-terrain-dem')) {
			state.map.addSource('mapbox-terrain-dem', {
				type: 'raster-dem',
				url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
				tileSize: 256,
				maxzoom: 15,
			});
		}

		state.map.setTerrain({ source: 'mapbox-terrain-dem', exaggeration: 1.5 });

		let isIdled = false;

		state.map.once('idle', () => (isIdled = true));

		while (!isIdled) {
			await sleep(10);
		}

		if (state.userCanNavigate) {
			state.map.addControl(
				new mapboxGeocoder({
					accessToken: Credentials.MAPBOX_ACCESS_TOKEN,
					mapboxgl: mapboxgl,
					localGeocoder: coordinatesGeocoder,
					placeholder: 'Place Name or Lat,Lon ...',
					marker: true,
					zoom: Constants.RELOCATION_ZOOM,
					collapsed: true,
					clearAndBlurOnEsc: true,
					clearOnBlur: true,
					limit: undefined,
					countries: undefined,
					language: undefined,
					reverseGeocode: true,
				}),
				'top-left'
			);

			let geocoderInput = state.doc.querySelector('input.mapboxgl-ctrl-geocoder--input');
			geocoderInput.placeholder = 'Place Name or Lat,Long ...';
			geocoderInput.title = 'Enter the name of a location, or latitude and longitude coordinates, separated with a space or comma.';

			geocoderInput.addEventListener('blur', () => {});
		}

		state.map.addControl(
			new mapboxgl.NavigationControl({
				visualizePitch: true,
				showCompass: true,
				// showCompass: false,
			}),
			'top-left'
		);
		//if (state.userCanEdit) {
		let modes = MapboxDraw.modes;
		modes.static = StaticMode;

		state.draw = new MapboxDraw({
			modes: modes,
			defaultMode: 'simple_select',
			displayControlsDefault: false,
			controls: { polygon: state.userCanEdit },
			userProperties: true,
			style: {
				id: 'gl-draw-polygon-fill-image',
				type: 'fill',
				filter: ['all', ['==', '$type', 'Polygon'], ['has', 'user_image']],
				sources: {
					id: 'visual',
					type: 'image',
				},
				paint: { 'fill-pattern': 'pattern' },
			},
		});

		state.map.addControl(state.draw, 'top-left');
		updateDrawMode();
		//}

		state.view360Control = new View360Control();
		//state.pitchControl = new PitchControl(state.view360Control);
		//state.map.addControl(state.pitchControl, 'top-left');

		state.map.addControl(state.view360Control, 'top-left');
		//state.dropPinControl = new DropPinControl({ userPins: state.user.userPins, fnUpdateUserSettings: updateUserSettings });
		//state.map.addControl(state.dropPinControl, 'top-left');

		// state.legendControl = new LegendControl();
		// state.map.addControl(state.legendControl, 'top-left');

		if (state.userCanNavigate) {
			// state.AOILibraryControl = new AOILibraryControl(
			// 	state.locations,
			// 	async (locationsId, from) => {
			// 		await getLocation(locationsId).then(async (location) => {
			// 			state.location = location;
			// 			await updateState(state, 'navigationFromAOILibrary');
			// 			await updateMapLocation(from);
			// 		});
			// 	},
			// 	state.userIsAdmin,
			// 	state.user.username
			// );
			// state.map.addControl(state.AOILibraryControl, 'top-right');
			// Array.from(state.locations)
			// 	.reverse()
			// 	.forEach((location) => {
			// 		state.AOILibraryControl.add(location);
			// 	});
		}

		state.hudControl = new HUDControl();
		state.map.addControl(state.hudControl, 'top-right');
		let copyRightText = '';

		let title = `version: ${appState.version}  mode: ${appState.user?.mode} server: ${appState.user?.server} `;
		copyRightText = () => `<div class="copyright-tooltip"  > &copy; BlueGreen Water Technologies <span class="tooltiptext">${title}</span> </div>`;

		state.map.addControl(
			new mapboxgl.AttributionControl({
				compact: false,
				customAttribution: `Lake Guard® View ${state.version}${Constants.DEV ? ' <span class="_red1">DEV</span>' : Constants.STAGING ? ' <span class="_red1">STAGING</span>' : Constants.TEST ? ' <span class="_red1">TEST</span>' : Constants.DEMO ? ' <span class="_cyan1">DEMO</span>' : ''}${
					Constants.API
				} ${copyRightText()} | &copy; ESA | &copy; Planet Labs | &copy; <a href="https://open-meteo.com/">Open-Meteo</a>`,
			}),
			'bottom-left'
		);

		const tooltip = document.querySelector('.copyright-tooltip');
		if (tooltip) {
			tooltip.addEventListener('click', () => {
				if (tooltip.classList.contains('visible')) {
					tooltip.classList.remove('visible');
				} else {
					tooltip.classList.add('visible');
				}
			});
		}

		let position = 'top-right';

		state.map.addControl(new mapboxgl.ScaleControl({ unit: state.user.units }), position);
		state.coordinatesControl = new CoordinatesControl(state, isTablet);

		state.map.addControl(state.coordinatesControl, position);

		createMapboxPolygons();

		if (state.userCanEdit) {
			state.doc.querySelector('.mapboxgl-ctrl-group:has(.mapbox-gl-draw_ctrl-draw-btn)').classList.add('mapboxgl-ctrl-draw');
			state.map.on('draw.selectionchange', drawSelectionChanged);
			// state.map.on('draw.create', drawSelectionChanged);
			state.map.on('draw.modechange', editDrawMode);
		}

		state.map.on('move', updateTelemetryDisplay);
		state.map.on('mousemove', updateTelemetryDisplay);

		state.mode = Constants.MODES.None;

		if (!state.userCanNavigate) {
			state.location = state.locations[0];
			await updateState(state, 'navigationOnSingleAOI');
			// state = updateMapLocation('createMapboxComponents');
			state = await navigateToLocation(state.locations[0].locationsId);
		}

		await updateState(state, 'createMapboxComponents');
		return state;
	};

	const navigateToLocation = async (locationsId) => {
		//besides of loading an AOI, there is a limitation of loading the AOI data -> if we exceed timeout we will stop the loading thread and show a message that indicates so.
		// if (locationsId === appState.location.locationsId) {
		// 	return appState;
		// }
		let state = { ...appState };
		setShowNewTrends(false);
		setLoading(true);

		showNavigator.current = false;
		state.hasTrendDataForNavigator = false;
		state.map.resize();

		let apiTimeout = false;

		try {
			let timeoutResult = '';
			const timeoutHandler = new Promise((resolve, reject) => {
				//after 15 seconds stop data load and show appropriate message
				setTimeout(() => {
					timeoutResult = AOI_LOAD_TIME_EXCEED;
					resolve(AOI_LOAD_TIME_EXCEED);
				}, AOI_MAXIMUM_LOAD_TIME);
			});

			const loadLocationData = new Promise((resolve, reject) => {
				getLocation(locationsId).then(async (location) => {
					setTimeout(async () => {
						if (timeoutResult === '') {
							// if  timeoutResult still empty -> AOI load time stil smaller than timeout limit.
							resolve(AOI_LOAD_TIME_VALID);

							state.location = location;
							if (location.satellites && location.satellites.S3?.dates?.length > 0) {
								setSatelliteView(SATELLITES.copernicus);
							} else if (location.satellites && location.satellites.PSD?.dates?.length > 0) {
								setSatelliteView(SATELLITES.planet);
							}
							state = await removeAllLayers();
							state.hasTrendDataForNavigator = false;
							state = await updateState(state, 'navigationFromAOILibrary');
							state.hasTrendDataForNavigator = false;

							let dateToLoad;

							if (location.satellites['S3'] && location.satellites['S3'].dates && location.satellites['S3'].dates.length) {
								dateToLoad = location.satellites['S3'].dates[0];
							} else {
								dateToLoad = formatDate(new Date(), Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
							}

							setLocationSelectedDate(new Date(parseISO(dateToLoad)));
							setSelectedDate(dayjs(format(new Date(parseISO(`${dateToLoad}`)), Constants.DATE_FORMATS.SHORT_DATE_FORMAT)));
							state.location = location;
							state = await goToLocation(state, updateState, displayImageryNew, CheapRuler, area, showNavigator, parseDate, setShowDefaultLayers, getTrendData, setShowNewTrends, setLoading, dateToLoad);
						} else {
							state.location = defaultLocation;
							state = await updateState(state);
						}
					}, 0);
				});
			});

			const result = await Promise.race([timeoutHandler, loadLocationData]);
			if (result === AOI_LOAD_TIME_EXCEED) {
				throw new Error(AOI_LOAD_TIME_EXCEED);
			}
		} catch (e) {
			setLoading(false);
			setModalOpened(true);
			apiTimeout = true;

			if (e?.message === AOI_LOAD_TIME_EXCEED) {
				setModalProps({
					open: true,
					closeModal: async () => {
						await setModalOpened(false);
						setOpenAoiList(true);
					},
					modalTitle: 'The service is not responding', //The service is not responding, please try later.
					type: ERROR_MODAL,
					actionButtonClick: exportDataRanges,
					actionButtonTitle: 'Close',
					closeActionButtonTitle: 'Close',
					setModalOpened: setModalOpened,
					location: appState.location,
					errorMessage: 'Please visit back later, Data currently not available.',
				});
			} else {
				setModalProps({
					open: true,
					closeModal: async () => {
						await setModalOpened(false);
						setOpenAoiList(true);
					},
					modalTitle: 'Error - Can not view location',
					type: ERROR_MODAL,
					actionButtonClick: exportDataRanges,
					actionButtonTitle: 'Close',
					closeActionButtonTitle: 'Close',
					setModalOpened: setModalOpened,
					location: appState.location,
					errorMessage: 'Please visit back later, Data currently not available.',
				});
			}
		}
		if (apiTimeout) {
			state.location = defaultLocation;
			state = await updateState(state);
		}
		return state;
	};

	const returnToLocation = async () => {
		if (appState && appState.location && appState.location.locationsId) {
			return backToCurrentLocation(appState, CheapRuler);
		}
	};

	const createMapBox = async (state = appState) => {
		const geolocation = await getGeolocation();

		let logoPosition = 'bottom-left';
		state.map = new mapboxgl.Map({
			container: 'map',
			accessToken: Credentials.MAPBOX_ACCESS_TOKEN,
			zoom: Constants.DEFAULT_ZOOM,
			center: [geolocation.longitude, geolocation.latitude],
			style: Constants.MAPBOX_STYLE,
			attributionControl: false,
			logoPosition: logoPosition,
			antialias: true,
			projection: 'globe',
		});

		state.imageryLayers = [];
		state.map.on('load', async () => {
			await createMapBoxComponents(state).then(async (state) => {
				// state.map.setStyle('mapbox://styles/mapbox/' + 'outdoors-v12');

				await updateState(state, 'createMapBox::onload');
			});
		});
		return state;
	};

	const [modalProps, setModalProps] = useState({
		open: modalOpened,
		closeModal: () => setModalOpened(false),
		modalTitle: 'Export Data',
		type: EXPORT_MODAL,
		actionButtonClick: exportDataRanges,
		startDateTitle: 'From:',
		endDateTitle: 'To:',
		actionButtonTitle: 'Export',
		closeActionButtonTitle: 'Close',
		setModalOpened: setModalOpened,
		location: appState.location,
	});

	const updateMapLocation = async (from = 'UNKNOWN') => {
		log('updateMapLocation from', from);
		let state = appState,
			location = state.location,
			isPreviousLocation = location.locationsId === state.previousLocationsId;

		if (isPreviousLocation) {
			state.location = state.previousLocation;
		} else {
			state.previousLocation = state.location;
		}

		const //isRestricted = !isBlank(state.user.restrictedAreas),
			//hasRestrictedDate = isRestricted && state.user.restrictedAreas.find((_restrictedArea) => _restrictedArea.location === state.location.name).startDate !== undefined,
			//restrictedDate = undefined, hasRestrictedDate ? state.user.restrictedAreas.find((_restrictedArea) => _restrictedArea.location === state.location.name).startDate : state.location.firstImagery,
			geometry = location.geometry.geometry,
			boundingBox = bbox(geometry),
			zoomstarted = async () => {
				let state = appState;
				state.map.off('zoomstart', zoomstarted);
				state.map.once('zoomend', mapReady);
			},
			mapReady = async () => {
				state.map.off('zoomend', mapReady);
				// confirm that the location is centred on the map
				let centre = state.map.getCenter();

				if (!state.ruler.insideBBox(state.ruler.bufferPoint([centre.lng, centre.lat], 0.1), rulerBBox)) {
					log('mapReady zoom ended, but map was not updated.  re-zooming', new Date().toLocaleTimeString());
					state.map.once('zoomstart', async () => await zoomstarted());
					state.map.fitBounds(rulerBBox);
				} else if (state.location.lastImagery) {
					log('mapReady updating location', new Date().toLocaleTimeString());

					location.endDate = parseDate(location.lastImagery.toString(), Constants.DATE_FORMATS.SHORT_DATE_FORMAT, new Date());
					location.startDate = parseDate(location.firstImagery.toString(), Constants.DATE_FORMATS.SHORT_DATE_FORMAT, new Date());
					state.location = location;
					state.locations[state.locationIndex] = !isPreviousLocation ? location : state.locations[state.locationIndex];
					state.coordinatesControl.updateCoordinates();
					state.analysis = 'trueColour';
					state.mode = Constants.MODES.View;
					state.imagery = undefined;
					state.imageryDate = undefined;
					state.map.setMaxBounds(!appState.userCanNavigate && appState?.restrictedAreas?.length === 1 ? state.map.getBounds() : undefined);
					loadingAborted = false;
					abortLoading = false;
					state = await updateState(await displayImagery('updateMapLocation::mapReady', state), 'updateMapLocation::mapReady');

					log('mapReady finished', new Date().toLocaleTimeString());
				} else {
					await updateState(state, 'updateMapLocation::mapReady');

					let buttons = [
							{
								label: state.userCanNavigate && state.locations.length > 1 ? 'Close' : 'Retry',
								onClick: async () => {
									state = state.userCanNavigate && state.locations.length > 1 ? await resetDisplayedLocation(false) : await updateMapLocation('updateMapLocation::mapReady::retry');
								},
							},
						],
						message = 'Imagery is currently being prepared for this location. Please try again later.';

					confirmAlert({
						title: state.location.name,
						message: message,
						closeOnEscape: false,
						closeOnClickOutside: false,
						buttons: buttons,
					});
				}

				return state;
			};

		state.lastRequestedLocationsId = !isPreviousLocation ? location.locationsId : state.lastRequestedLocationsId;
		log('updateMapLocation', 'pitch', state.map.getPitch());
		state.locationIndex = state.locations.findIndex((_location) => _location.locationsId === state.location.locationsId);
		// set the top left latitude as the ruler constructor
		state.ruler = new CheapRuler(boundingBox[1]);
		let rulerBBox = state.ruler.bufferBBox(boundingBox, 5);

		if (!isPreviousLocation) {
			state.imagery = undefined;
			state.images = undefined;
		}

		log('updateMapLocation', 'isPreviousLocation', isPreviousLocation);

		if (isPreviousLocation) {
			state = await mapReady();
		} else {
			// let polygon = state.location.geometry;
			state.map.once('zoomstart', async () => await zoomstarted());
			state.method = 'updateMapLocation::fitBounds';
			state = await updateState(state, 'updateMapLocation::fitBounds');
			state.map.fitBounds(rulerBBox);
		}

		showNavigator.current = true;
		return state;
	};

	// Trend Methods

	const showTrends = async (event) => {
		//toggle trends show/hide. - old method
		const state = { ...appState };

		// if (!state.hasTrendData && event) {
		// 	state.hasTrendData = !state.trendData.Error;
		// } else if (event) {
		// 	state.hasTrendData = false;
		// }

		let action;
		if (state.hasTrendData) {
			state.hasTrendData = false;
			action = 'toggle trends popup close';
		} else {
			state.hasTrendData = true;
			action = 'showTrends';
		}

		await updateState(state, action);
	};

	const logoutAction = async () => {
		await fetch({ url: `logout` });
		window && window.localStorage.removeItem('usersId');
		window && window.localStorage.removeItem('tokens');
		window && window.history.replaceState({}, '', '/');
		window && window.location.reload();
	};

	const closeTrends = async () => {
		//hide trends modal.
		let state = appState;
		appState.hasTrendData = false;
		await updateState(state, 'closeTrends');
	};

	// React Specfic Methods
	// componentDidMount = async () => {
	// 	await updateState(await getUserSettings(appState), 'componentDidMount');
	// 	componentIsMounted = true;
	// };

	//   const shouldUpdate =
	//     !_.includes(
	//       [
	//         "createMapbox",
	//         "createMapboxComponents",
	//         "createMapboxPolygons",
	//         "getAppSettings",
	//         "getUserSettings",
	//         "getUserSettings:: !componentIsMounted",
	//         "updateUserSettings",
	//         "updateTelemetryDisplay",
	//         "showTrueColour",
	//         "showBGI",
	//         "showBloom",
	//         "getUserSettings::getAppSettings",
	//       ],
	//       appState?.method
	//     ) === true;

	// 	const shouldComponentUpdateValue = ((componentIsMounted ) || true) &&
	// 			!_.includes(['createMapbox', 'createMapboxComponents', 'createMapboxPolygons', 'getAppSettings', 'getUserSettings', 'getUserSettings:: !componentIsMounted', 'updateUserSettings', 'updateTelemetryDisplay', 'showTrueColour', 'showBGI', 'showBloom', 'getUserSettings::getAppSettings'], this?.state?.method) ===
	// 				true;

	const elementsToChange = ['mapboxgl-ctrl-zoom-in', 'mapboxgl-ctrl-geocoder mapboxgl-ctrl mapboxgl-ctrl-geocoder--collapsed', 'mapboxgl-ctrl-zoom-out', 'mapboxgl-ctrl-compass', 'mapbox-gl-draw_polygon', 'mapboxgl-ctrl-scale', 'mapboxgl-ctrl-attrib', 'mapboxgl-ctrl-fullscreen'];
	const addClassesToControls = () => {
		if (isTablet) {
			for (let i = 0; i < elementsToChange.length; i++) {
				let element = document.getElementsByClassName(elementsToChange[i]);
				element = element[0];

				if (element && element.classList && element.classList.length >= 0) {
					element.classList.add('tablet');
				}
			}
		}
	};

	useEffect(() => {
		const todo = async () => {
			if (!appState.refreshInterval) {
				//initalize the websocket client

				// TODO uncomment the following block to activate the websocket client
				/*if(isBlank(appState.wsClient)) {
					appState.wsClient = new WSService();
				}*/

				await updateState(await getUserSettings(appState), 'useEffect->todo');
			}
		};
		if (document && document.getElementsByClassName(elementsToChange[0]) && document.getElementsByClassName(elementsToChange[0]).length > 0) {
			addClassesToControls();
		}

		todo();
	});

	// const shouldComponentUpdate = (nextProps, nextState) => {
	// 	return (
	// 		((componentIsMounted && nextState) || true) &&
	// 		!_.includes(['createMapbox', 'createMapboxComponents', 'createMapboxPolygons', 'getAppSettings', 'getUserSettings', 'getUserSettings:: !componentIsMounted', 'updateUserSettings', 'updateTelemetryDisplay', 'showTrueColour', 'showBGI', 'showBloom', 'getUserSettings::getAppSettings'], this?.state?.method) ===
	// 			true
	// 	);
	// };

	if (appState.doc && appState.doc.querySelector('.mapboxgl-ctrl-top-left')) {
		appState.doc.querySelector('.mapboxgl-ctrl-top-left').classList.add('controls-new-ui');
		if (isTablet) {
			appState.doc.querySelector('.mapboxgl-ctrl-top-left').classList.add('tablet');
		}
		if (appState.user.groups && !_.includes(appState.user.groups, 'expert')) {
			appState.doc.querySelector('.mapboxgl-ctrl-top-left').classList.add('basic-user');
		}
	}

	// const layerManagerProps = {
	// 	..._.pick(appState, ['mode', 'images', 'location', 'locationIndex', 'userCanNavigate', 'imagery', 'imageryDate', 'method', 'hasImageryDetails', 'user', 'is3D']),
	// 	hasImagery: !abortLoading && !loadingAborted && !isBlank(appState?.location?.firstImagery) && !isBlank(appState?.location?.lastImagery) && !isBlank(appState?.images),
	// 	close: closeLayerManager,
	// 	units: appState.user?.units,
	// 	showBGI: showBGI,
	// 	showBloom: showBloom,
	// 	showTrueColour: showTrueColour,
	// 	showPlanet: showPlanet,
	// 	togglePitch: togglePitch,
	// 	rotate: rotate,
	// 	isProUser: isProUser,
	// };

	// const locationEditorData = {
	// 	..._.pick(appState, ['mode', 'images', 'location', 'locationIndex', 'method', 'user', 'users', 'userIsAdmin']),
	// 	isAddLocation: appState.mode === Constants.MODES.Add,
	// 	isModifyLocation: appState.mode === Constants.MODES.Modify,
	// 	setLocationName: setLocationName,
	// 	units: appState.user?.units,
	// 	setDateRange: setDateRange,
	// 	setDescription: setDescription,
	// 	setMembers: setMembers,
	// 	setAccess: setAccess,
	// 	saveLocation: appState.mode === Constants.MODES.Add ? createLocation : updateLocation,
	// 	cancelSaveLocation: () => (appState.mode === Constants.MODES.Add ? resetDisplayedLocation(true) : closeModifyLocation()),
	// };

	const lcationModalProps = {
		..._.pick(appState, ['mode', 'images', 'location', 'locationIndex', 'method', 'user', 'users', 'userIsAdmin']),
		modalTitle: appState.mode === 'Add' ? 'Create Location' : 'Modify Location',
		location: appState.location,
		user: appState.user,
		setLocationName: setLocationName,
		setDescription: setDescription,
		setDateRange: setDateRange,
		setAccess: setAccess,
		setMembers: setMembers,
		users: appState.users,
		saveLocation: appState.mode === Constants.MODES.Add ? createLocation : updateLocation,
		cancelSaveLocation: () => (appState.mode === Constants.MODES.Add ? resetDisplayedLocation(true) : closeModifyLocation()),
	};

	// const navigatorProps = {
	// 	..._.pick(appState, [
	// 		'mode',
	// 		'images',
	// 		'location',
	// 		'locationIndex',
	// 		'userCanNavigate',
	// 		'analysis',
	// 		'modifyLocation',
	// 		'lastDateDisplayed',
	// 		'imageryDate',
	// 		'method',
	// 		'exportRange',
	// 		'swiperDecorationsLength',
	// 		'swiperDivisor',
	// 		'user',
	// 		'hasTrendDataForNavigator',
	// 		'exportLocationData',
	// 		'doc',
	// 		'userIsAdmin',
	// 		'layerLoading',
	// 	]),
	// 	isImageryNavigator: true,
	// 	hasImagery: !loadingAborted && !abortLoading && !isBlank(appState?.location?.firstImagery) && !isBlank(appState?.location?.lastImagery) && !isBlank(appState?.images) && appState.mode === Constants.MODES.View,
	// 	units: appState.user.units,
	// 	scrollRight: scrollRight,
	// 	scrollLeft: scrollLeft,
	// 	setStartMonthFilter: setStartMonthFilter,
	// 	activateTimeSeries: activateTimeSeries,
	// 	setTimeSeries: setTimeSeries,
	// 	startTimeSeries: startTimeSeries,
	// 	stopTimeSeries: stopTimeSeries,
	// 	setDateRange: setDateRange,
	// 	setExportRange: setExportRange,
	// 	updateLocation: updateLocation,
	// 	cancelImagerySelectorAction: cancelImagerySelectorAction,
	// 	setAnalysis: setAnalysis,
	// 	showLayerManager: showLayerManager,
	// 	close: resetDisplayedLocation,
	// 	modifyLocation: modifyLocation,
	// 	deleteLocation: deleteLocation,
	// 	exportData: exportData,
	// 	showTrends: showTrends,
	// 	updateExportLocationData: updateExportLocationData,
	// 	closeLayerManager: closeLayerManager,
	// };

	const trendsProps = appState.trendProps
		? { ...appState.trendProps }
		: {
				..._.pick(appState, ['hasTrendData', 'trendData', 'doc', 'images', 'analysis', 'user']),
				units: appState.user.units,
				close: closeTrends,
				updateTrends: showTrends,
		  };

	const imagery = getCurrentImagery();

	const topbarProps = {
		options: appState.locations,
		images: appState.images,
		setLocation: navigateToLocation,
		logoutAction: logoutAction,
		selectedLocation: appState.location,
		editLocation: () => modifyLocation(appState, setcloseLocationPopupClicked, updateState, confirmAlert),
		setModalOpened: setModalOpened,
		allowExport: isAuthorized(appState.user, ['expert']),
		setStartMonthFilter: setStartMonthFilterNew,
		selectedLayers: selectedLayers,
		setSelectedLayers,
		showLayersAfterDateChange: showLayersAfterDateChange,
		satelliteView: satelliteView,
		setSatelliteView: async (value) => {
			setSatelliteView(value);
		},
		trendData: appState.trendData,
		defaultDate: defaultDate,
		showDefaultLayers: showDefaultLayers,
		setShowDefaultLayers: setShowDefaultLayers,
		lastDateDisplayed: appState.lastDateDisplayed,
		isProUser: isProUser,
		removeAllLayers: removeAllLayers,
		selectedDate,
		setSelectedDate,
		userCanEdit: appState.userCanEdit,
		user: appState.user,
		loading: loading,
		setLoading: setLoading,
		// confirmModalState:confirmModalState,
		setConfirmModalState: setConfirmModalState,
		isTablet: isTablet,
		getCurrentImagery: getCurrentImagery,
		is3D: appState.is3D,
		togglePitch,
		rotate,
		imagery: imagery,
		userMode: appState.userMode,
		setModalProps: setModalProps,
		exportDataRanges: exportDataRanges,
		planetImagery: appState.planetImagery,
		openAoiList: openAoiList,
		setOpenAoiList: setOpenAoiList,
	};

	const newTrendsProps = {};

	const layerManagerComponentProps = {
		selectedLocation: appState.location,
		selectedLayers: selectedLayers,
		setSelectedLayers: setSelectedLayers,
		showLayerNew: showLayerNew,
		satelliteView,
		layerIconDisabled: appState.lastDateDisplayed || (appState.imageryLayers && appState.imageryLayers.length > 0),
		isTablet: isTablet,
		isPortrait: isPortrait,
	};

	const notificationProps = {
		open: showNotification.show,
		close: () => setShowNotification({ show: false, severity: '', msg: '' }),
		severity: showNotification.severity,
		msg: showNotification.msg,
	};

	const widthAfterResize = isTablet ? (collapseTrends ? 'calc(100% - 0px)' : 'calc(100% - 400px)') : collapseTrends ? 'calc(100% - 0px)' : 'calc(100% - 487px)';

	let confirmModalProps = {
		confirmButtonText: confirmModalState ? confirmModalState.confirmButtonText : '',
		cancelButtonText: 'Cancel',
		open: confirmModalState ? confirmModalState.open : false,
		message: confirmModalState ? confirmModalState.message : '',
		confirmButtonCb: confirmModalState ? confirmModalState.confirmButtonCb : () => {},
		title: confirmModalState ? confirmModalState.title : '',
		cancelButtonCb: confirmModalState ? confirmModalState.cancelButtonCb : () => {},
		message2: confirmModalState ? confirmModalState.message2 : '',
	};
	let newTrendsFlag =
		showNewTrends &&
		appState.hasTrendDataForNavigator &&
		// && appState.lastDateDisplayed
		appState.location?.name;

	const resizeMap = (state) => {
		if (!state.map) return;
		setTimeout(() => {
			state.map.resize();
		}, 10);
	};

	window.addEventListener('orientationchange', (event) => {
		if (event.target.screen.orientation.angle === 0 || event.target.screen.orientation.angle === 180) {
			// portrait
			if (isPortrait === false) {
				setIsPortrait(true);
				resizeMap(appState);
			}
		}

		if (event.target.screen.orientation.angle === 90 || event.target.screen.orientation.angle === 270) {
			// landscape
			if (isPortrait === true) {
				setIsPortrait(false);
				resizeMap(appState);
			}
		}
	});

	if (window.screen.orientation.type.includes('portrait')) {
		if (!isPortrait) {
			setIsPortrait(true);
			resizeMap(appState);
		}
	}
	if (window.screen.orientation.type.includes('landscape')) {
		if (isPortrait) {
			setIsPortrait(false);
			resizeMap(appState);
		}
	}

	let collapseTrendsClassName = 'read-more';
	if (isTablet) {
		collapseTrendsClassName = collapseTrendsClassName + ' tablet';
	}
	if (collapseTrends) {
		collapseTrendsClassName = collapseTrendsClassName + ' collapsed';
	}

	let basicUserClassName = '';
	if (appState.user.groups && !_.includes(appState.user.groups, 'expert')) {
		basicUserClassName = 'basic-user';
	}

	const showLocatoinModal = appState.mode === Constants.MODES.Add || appState.mode === Constants.MODES.Update;
	return (
		<div className="LakeGuardView">
			<ProSidebarProvider>
				{/*<Sidebar>
						<Menu>
							<MenuItem> Home </MenuItem>
							<MenuItem> Map </MenuItem>
						</Menu>
		</Sidebar>*/}
				<div className={'map'} id="map" style={showNewTrends && appState.hasTrendDataForNavigator && appState.lastDateDisplayed && appState.location?.name ? { width: widthAfterResize } : { width: '100%' }}>
					<img className="loading" src="/logo.png" alt="BlueGreen Water Technologies" width="50%" />
					{topbarProps.options?.length ? (
						<div className="scale-icon-tablet">
							<svg width="73" height="15" viewBox="0 0 73 15" fill="none">
								<g filter="url(#filter0_d_3201_9466)">
									<path fillRule="evenodd" clipRule="evenodd" d="M4.5 3H5.5V8.5H67.5V3H68.5V9.5H4.5V3Z" fill="white" shapeRendering="crispEdges" />
									<path d="M4.5 2.5H4V3V9.5V10H4.5H68.5H69V9.5V3V2.5H68.5H67.5H67V3V8H6V3V2.5H5.5H4.5Z" stroke="black" strokeOpacity="0.5" shapeRendering="crispEdges" />
								</g>
								<defs>
									<filter id="filter0_d_3201_9466" x="0.5" y="0" width="72" height="14.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
										<feFlood floodOpacity="0" result="BackgroundImageFix" />
										<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
										<feOffset dy="1" />
										<feGaussianBlur stdDeviation="1.5" />
										<feComposite in2="hardAlpha" operator="out" />
										<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
										<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3201_9466" />
										<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3201_9466" result="shape" />
									</filter>
								</defs>
							</svg>
						</div>
					) : (
						''
					)}
				</div>
				<ThemeProvider theme={appState.darkTheme}>
					<CssBaseline />
					{/* {!closeLocationPopupClicked && <LocationEditor data={locationEditorData} />} */}
					{!closeLocationPopupClicked && showLocatoinModal ? <LocationModal {...lcationModalProps} /> : ''}
				</ThemeProvider>

				{topbarProps.options?.length ? <TopBar {...topbarProps} /> : ''}

				{/* { navigatorProps.hasImagery && showNavigator.current && <Navigator data={navigatorProps} />} */}

				{/* {layerManagerProps.imagery && <LayerManager selectedLayers={selectedLayers} data={layerManagerProps} />} */}

				{/* {trendsProps.hasTrendData && <Trends data={trendsProps} />} */}

				{(selectedLayers.includes(BLOOM) || selectedLayers.includes(CLOUDS) || selectedLayers.includes(INTERFERENCES) || selectedLayers.includes(PLANET_BGI) || selectedLayers.includes(PLANET_BLOOM) || selectedLayers.includes(PLANET_CLOUDS)) && appState.lastDateDisplayed && (
					<LegendComponent satelliteView={satelliteView} isTablet={isTablet} />
				)}

				{/* { <LegendComponent satelliteView={satelliteView} isTablet={isTablet} />} */}

				{modalOpened && <MainModal {...modalProps} />}

				{showNotification && <MaterialNotification {...notificationProps} />}

				{newTrendsFlag ? (
					<MainTrends
						data={{
							...trendsProps,
							selectedDate: selectedDate ? selectedDate : defaultDate,
							isTablet: isTablet,
							location: appState.location,
							user: appState.user,
							collapseTrends: collapseTrends,
							userMode: appState.userMode,
							satelliteView: satelliteView,
							trendDataForAllSatellites: appState.trendDataForAllSatellites,
						}}
						{...newTrendsProps}
					/>
				) : (
					''
				)}

				{topbarProps.options?.length ? <LayerManagerComponent {...layerManagerComponentProps} /> : ''}

				{topbarProps.options?.length ? <div className={isTablet ? 'first-main-divider tablet' : `first-main-divider ${basicUserClassName}`} /> : ''}

				{topbarProps.options?.length ? <div className={isTablet ? 'second-main-divider tablet' : `second-main-divider ${basicUserClassName}`} /> : ''}

				{topbarProps.options?.length ? <FilterCenterFocusIcon className={isTablet ? 'center-icon tablet cursor-pointer' : ` center-icon cursor-pointer ${basicUserClassName}`} onClick={returnToLocation} /> : ''}

				{confirmModalState && <ConfirmModal {...confirmModalProps} />}

				{newTrendsFlag ? (
					<div className={collapseTrendsClassName}>
						<Tooltip title="Trends">
							<IconButton
								onClick={() => {
									setCollapseTrends(!collapseTrends);
									resizeMap(appState);
								}}>
								<ReadMoreIcon fontSize="large" style={collapseTrends ? { transform: 'rotate(180deg)' } : {}} />
							</IconButton>
						</Tooltip>
					</div>
				) : (
					''
				)}

				{loading && (
					<div className="loader">
						{' '}
						<CircularProgress color="inherit" />{' '}
					</div>
				)}
			</ProSidebarProvider>
		</div>
	);
}

export default LakeGuardView;
