/**
 * Description of Constants.js
 *
 * This module contains constant values used throughout the system
 *
 * @author github.com/doncarlosone
 * @copyright 2023 BlueGreen Water Technologies
 */

import _ from 'lodash';

const version = {
		version: '4.2.7',
		demo: window.location.href.includes('demo'),
		test: window.location.href.includes('test'),
		staging: window.location.href.includes('staging'),
		dev: window.location.href.includes('localhost') || window.location.href.includes('charles') || window.location.href.includes('majde') || !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
	},
	modes = ['View', 'Add', 'Edit', 'Delete', 'Loading', 'Closing', 'Closed', 'None', 'DropPin'],
	dateFormats = {
		DATE_FORMAT: 'yyyy-MM-dd',
		FIXED_UTC_FORMAT: 'yyyy-MM-ddT00:00:00Z',
		SHORT_DATE_FORMAT: 'yyyyMMdd',
		MONTH_FORMAT: 'MMM',
		SHORT_MONTH_NAME_FORMAT: 'MMM d',
		MONTH_NAME_FORMAT: `MMM d ''yy`,
		MONTH_NAME_LONG_YEAR_FORMAT: `MMMM d, yyyy`,
		LONG_DATE: 'EEEE, MMMM d, yyyy',
		SHORT_MONTH_YEAR_FORMAT: `MMM yyyy`,
		MONTH_YEAR_FORMAT: `MMM ''yy`,
		QUARTER_FORMAT: `Q`,
		SHORT_QUARTER_YEAR_FORMAT: `Q yyyy`,
		QUARTER_YEAR_FORMAT: `Q ''yy`,
		YEAR_FORMAT: 'yyyy',
	};

let localAPI = 'lgv-api-staging';
// let localAPI = 'lgv-api-dev'; // direct all API queries to the LGVDEV database
// let localAPI = 'lgv-api-test';

//localAPI = 'local';

const legendsTemplate = {
	copernicus: {
		bgi: [
			{ threshold: 0, colour: 'transparent', id: 'Land', label: 'Land', title: 'Land', inverse: false, display: false },
			{ threshold: 1, colour: '#fff', id: 'Clouds', label: 'Clouds', title: 'Cloud Cover', inverse: true, display: true },
			// { threshold: 2, colour: '#cbc1c7', id: 'SML', label: 'SML', title: 'Surface Micro-Layer', inverse: true, display: true },
			// { threshold: 3, colour: '#e9d568', id: 'SS', label: 'SS', title: 'Suspended Sediments', inverse: true, display: true },
			// { threshold: 4, colour: '#7a806c', id: 'CDOM', label: 'CDOM', title: 'Colored Dissolved Organic Matter', inverse: true, display: true },
			{ threshold: 2, colour: '#7A806C ', id: 'SML', label: 'SML', title: 'Surface Micro-Layer', inverse: true, display: true },
			{ threshold: 3, colour: '#7A806C ', id: 'SS', label: 'SS', title: 'Suspended Sediments', inverse: true, display: true },
			{ threshold: 4, colour: '#7A806C ', id: 'CDOM', label: 'CDOM', title: 'Colored Dissolved Organic Matter', inverse: true, display: true },
			{ threshold: 5, colour: '#369e97', id: 'BloomLevel', label: 'Bloom', title: 'Harmful Algae Bloom', inverse: true, display: true },
		],
		bloom: [
			{
				threshold: 350,
				colour: '#5c0000',
				label: 'Over 350 mg/m³',
				title: 'Over 350 mg/m³',
				inverse: false,
				display: true,
			},
			{
				threshold: 250,
				colour: '#920000',
				label: '250 - 350 mg/m³',
				title: '250 - 350 mg/m³',
				inverse: false,
				display: true,
			},
			{
				threshold: 200,
				colour: '#f60708',
				label: '200 - 250 mg/m³',
				title: '200 - 250 mg/m³',
				inverse: true,
				display: true,
			},
			{
				threshold: 150,
				colour: '#f9590d',
				label: '150- 200 mg/m³',
				title: '150- 200 mg/m³',
				inverse: true,
				display: true,
			},
			{
				threshold: 100,
				colour: '#FC8C0B',
				label: '100 - 150 mg/m³',
				title: '100 - 150 mg/m³',
				inverse: true,
				display: true,
			},
			{
				threshold: 75,
				colour: '#ffbf09',
				label: '75 - 100 mg/m³',
				title: '75 - 100 mg/m³',
				inverse: true,
				display: true,
			},
			{
				threshold: 50,
				colour: '#feff02',
				label: '50 - 75 mg/m³',
				title: 'Over 350 mg/m³',
				inverse: true,
				display: true,
			},
			{
				threshold: 25,
				colour: '#07fd09',
				label: '25 - 50 mg/m³',
				title: '25 - 50 mg/m³',
				inverse: true,
				display: true,
			},
			{
				threshold: 1,
				colour: '#0104fe',
				label: '1 - 25 mg/m³',
				title: '1 - 25 mg/m³',
				inverse: false,
				display: true,
			},
			{
				threshold: 0,
				colour: '#369e97',
				label: 'Under 1 mg/m³',
				title: 'Under 1 mg/m³',
				inverse: true,
				display: false,
			},
		],
	},
	planet: {
		bgi: [
			{ threshold: 0, colour: 'transparent', id: 'Land', label: 'Land', title: 'Land', inverse: false, display: false },
			{ threshold: 1, colour: '#fff', id: 'Clouds', label: 'Clouds', title: 'Cloud Cover', inverse: true, display: true },
			{ threshold: 3, colour: '#e9d568', id: 'SS', label: 'SS', title: 'Suspended Sediments', inverse: true, display: true },
			{ threshold: 5, colour: '#369e97', id: 'BloomLevel', label: 'Bloom', title: 'Harmful Algae Bloom', inverse: true, display: true },
		],
		bloom: [
			{
				threshold: 2000,
				colour: '#690000',
				label: 'Over 2000 mg/m³',
				title: 'Over 2000 mg/m³',
				inverse: false,
				display: true,
			},
			{
				threshold: 1900,
				colour: '#A50000',
				label: 'Over 1900 mg/m³',
				title: 'Over 1900 mg/m³',
				inverse: false,
				display: true,
			},
			{
				threshold: 1800,
				colour: '#E60000',
				label: 'Over 1800 mg/m³',
				title: 'Over 1800 mg/m³',
				inverse: false,
				display: true,
			},
			{
				threshold: 1700,
				colour: '#FF1F00',
				label: 'Over 1700 mg/m³',
				title: 'Over 1700 mg/m³',
				inverse: true,
				display: true,
			},
			{
				threshold: 1600,
				colour: '#FF4F00',
				label: 'Over 1600 mg/m³',
				title: 'Over 1600 mg/m³',
				inverse: true,
				display: true,
			},
			{
				threshold: 1500,
				colour: '#FF8300',
				label: 'Over 1500 mg/m³',
				title: 'Over 1500 mg/m³',
				inverse: true,
				display: true,
			},
			{
				threshold: 1400,
				colour: '#FFB700',
				label: 'Over 1400 mg/m³',
				title: 'Over 1400 mg/m³',
				inverse: false,
				display: true,
			},
			{
				threshold: 1300,
				colour: '#FFEB00',
				label: 'Over 1300 mg/m³',
				title: 'Over 1300 mg/m³',
				inverse: true,
				display: true,
			},
			{
				threshold: 1200,
				colour: '#C8FF00',
				label: 'Over 1200 mg/m³',
				title: 'Over 1200 mg/m³',
				inverse: true,
				display: true,
			},
			{
				threshold: 1100,
				colour: '#60FF00',
				label: 'Over 1100 mg/m³',
				title: 'Over 1100 mg/m³',
				inverse: true,
				display: true,
			},
			{
				threshold: 1000,
				colour: '#00FF0F',
				label: 'Over 1000 mg/m³',
				title: 'Over 1000 mg/m³',
				inverse: true,
				display: true,
			},
			{
				threshold: 900,
				colour: '#00FF77',
				label: 'Over 900 mg/m³',
				title: 'Over 900 mg/m³',
				inverse: true,
				display: true,
			},
			{
				threshold: 800,
				colour: '#00FFD7',
				label: 'Over 800 mg/m³',
				title: 'Over 800 mg/m³',
				inverse: true,
				display: true,
			},
			{
				threshold: 700,
				colour: '#00D5FF',
				label: 'Over 700 mg/m³',
				title: 'Over 700 mg/m³',
				inverse: true,
				display: true,
			},
			{
				threshold: 600,
				colour: '#0090FF',
				label: 'Over 600 mg/m³',
				title: 'Over 600 mg/m³',
				inverse: false,
				display: true,
			},
			{
				threshold: 500,
				colour: '#004AFF',
				label: 'Over 500 mg/m³',
				title: 'Over 500 mg/m³',
				inverse: false,
				display: true,
			},
			{
				threshold: 400,
				colour: '#000AFF',
				label: 'Over 400 mg/m³',
				title: 'Over 400 mg/m³',
				inverse: false,
				display: true,
			},
			{
				threshold: 300,
				colour: '#2100DE',
				label: 'Over 300 mg/m³',
				title: 'Over 300 mg/m³',
				inverse: false,
				display: true,
			},
			{
				threshold: 200,
				colour: '#4800B7',
				label: 'Over 200 mg/m³',
				title: 'Over 200 mg/m³',
				inverse: false,
				display: true,
			},
			{
				threshold: 100,
				colour: '#6F0090',
				label: 'Over 100 mg/m³',
				title: 'Over 100 mg/m³',
				inverse: false,
				display: true,
			},
			{
				threshold: 0.01,
				colour: '#93006C',
				label: 'Over 0.01 mg/m³',
				title: 'Over 0.01 mg/m³',
				inverse: false,
				display: true,
			},
		],
	},
};

export const Constants = {
	TITLE: 'Lake Guard® View',
	VERSION: version.dev && localAPI !== 'api' ? 'DEV_VERSION' : version.demo ? 'DEMO_VERSION' : version.staging ? 'STAGING_VERSION' : version.test ? 'TEST_VERSION' : 'PRODUCTION_VERSION',
	PRODUCTION: !(version.staging || version.test || version.dev) || localAPI === 'api',
	DEMO: version.demo,
	TEST: version.test || (version.dev && localAPI !== 'api'),
	STAGING: version.staging,
	DEV: version.dev && localAPI !== 'api',

	JSON_HEADERS: { 'Content-Type': 'application/json' },

	API: version.dev && localAPI === 'local' ? ':LOCAL' : `${version.dev ? ':DEV' : version.demo ? ':DEMO' : version.staging ? ':STAGING' : version.test ? ':TEST' : ''}`,

	LGV_API: version.dev && localAPI === 'local' ? 'https://charles.lake-guard.com:3006/' : version.dev ? `https://${localAPI}.lake-guard.com` : version.test || version.staging ? `https://lgv-api-${version.test ? 'test' : 'staging'}.lake-guard.com` : `https://api.lake-guard.com/`,

	LGV_WSS: version.dev && localAPI === 'local' ? 'wss://charles.lake-guard.com:3100' : version.dev ? `wss://${localAPI}.lake-guard.com:3100` : version.test || version.staging ? `wss://lgv-api-${version.test ? 'test' : 'staging'}.lake-guard.com:3100` : `wss://api.lake-guard.com:3100`,

	MAPBOX_LAYER_NAMES: ['mapbox.satellite', 'mapbox.mapbox-streets-v8'],
	MAPBOX_API_ENDPOINT: 'https://api.mapbox.com',
	MAPBOX_STYLE: 'mapbox://styles/mapbox/satellite-streets-v11',

	DATE_FORMATS: dateFormats,

	MODES: _.keyBy(modes),

	DEFAULT_ZOOM: 2,
	RELOCATION_ZOOM: 11,

	ONE_DAY: 1000 * 60 * 60 * 24,
	ONE_WEEK: 1000 * 60 * 60 * 24 * 7,

	DEFAULT_IMAGE: '/LogoMark.png',
	BLANK: 'blank',
	THUMBNAIL_HEIGHT: 130,
	THUMBNAIL_WIDTH: 130,
	THUMBNAIL_MARGIN: 10,

	EARTH_CIRCUMFERENCE: 110540,
	SEN3_CELL_RADIUS: 300,
	HOVER_POPUP_WIDTH: '260px',

	SWIPER_SLIDE_MARGIN: 10, // swiper.spaceBetween
	SWIPER_SLIDE_FIRST_OFFSET: 30, // swiper.slidesOffsetBefore
	SWIPER_BUTTON_WIDTH: 37, // swiper.button is 27px + 10px margin

	MAX_POLYGON_AREA: 5000,
	MIN_POLYGON_AREA: 0.5,

	CARBON_FACTOR: 1.76,
	DEFAULT_AOI_DEPTH: 4,

	AREA_UNIT: {
		metric: 'area',
		imperial: 'acres',
	},
	UNITS: {
		metric: {
			area: {
				label: 'km²',
				factor: 1,
				decimals: 2,
			},
			hectares: {
				label: 'ha',
				factor: 0.01,
				decimals: 2,
			},
			elevation: {
				label: 'm',
				factor: 1,
				decimals: 1,
			},
			BloomLevel: {
				label: 'mg/m³',
				factor: 1,
				decimals: 1,
			},
			BloomSpread: {
				label: '%',
				factor: 1,
				decimals: 1,
			},
			Carbon: {
				label: 'mt',
				factor: 1,
				decimals: 0,
			},
			Temp: {
				label: '°C',
				factor: 1,
				decimals: 1,
			},
			Wind: {
				label: 'kph',
				factor: 1,
				decimals: 0,
			},
			Precipitation: {
				label: 'mm',
				factor: 1,
				decimals: 0,
			},
		},
		imperial: {
			area: {
				label: 'mi²',
				factor: 0.386102,
				decimals: 2,
			},
			acres: {
				label: 'ac',
				factor: 2.47105,
				decimals: 2,
			},
			elevation: {
				label: '′',
				factor: 3.28084,
				decimals: 1,
			},
			BloomLevel: {
				label: 'mg/m³',
				factor: 1,
				decimals: 1,
			},
			BloomSpread: {
				label: '%',
				factor: 1,
				decimals: 1,
			},
			Carbon: {
				label: 'mt',
				factor: 1,
				decimals: 0,
			},
			Temp: {
				label: '°F',
				factor: 1.8,
				decimals: 1,
			},
			Wind: {
				label: 'mph',
				factor: 0.621371,
				decimals: 0,
			},
			Precipitation: {
				label: 'in',
				factor: 0.03937008,
				decimals: 2,
			},
		},
	},

	LEGENDS: legendsTemplate,

	// unicode characters
	UP: '\u25B2',
	DOWN: '\u25BC',
	ARROW_RIGHT: '\u2192',
	ARROW_LEFT: '\u2190',
	ARROW_UP: '\u2191',
	ARROW_DOWN: '\u2193',
	ARROW_UP_DOWN: '\u2195',
	ARROW_LEFT_RIGHT: '\u2194',
	SHARE: '\u21A6',
	CLOSE: '\u2715',
	CLOCK: '\u23F2',
	CROSSHAIR: '\u271A',
	BULLET_POINT: '\u2022',

	// trend types
	TREND_TYPES: [
		{
			label: 'Bloom Level',
			key: 'BloomLevel',
			bloomTolerance: 0,
			cloudsTolerance: 90,
		},
		{
			label: 'Bloom Spread',
			key: 'BloomSpread',
		},
		{
			label: 'Carbon',
			key: 'Carbon',
			colour: '#058AB5',
		},
		{
			label: 'Water Components',
			key: 'WaterComponents',
		},
		{
			label: 'UV',
			key: 'UV',
		},
		{
			label: 'Temp',
			key: 'Temp',
		},
		{
			label: 'Precipitation',
			key: 'Precipitation',
		},
		{
			label: 'Wind',
			key: 'Wind',
		},
		{
			label: 'Visibility From Weather Cloud Cover',
			key: 'Visibility',
		},
	],

	// trend date ranges

	TREND_DATE_RANGES: [
		{ id: 'PastWeek', label: 'Past Week', format: dateFormats.SHORT_MONTH_NAME_FORMAT, legendFormat: dateFormats.SHORT_MONTH_NAME_FORMAT, title: 'By Day', tag: '' },
		{ id: 'PastMonth', label: 'Past Month', format: dateFormats.MONTH_NAME_FORMAT, legendFormat: dateFormats.MONTH_NAME_FORMAT, title: 'By Day', tag: '' },
		{ id: 'Past3Months', label: 'Past 3 Months', format: dateFormats.MONTH_NAME_FORMAT, legendFormat: dateFormats.MONTH_NAME_FORMAT, title: 'By Day', tag: '' },
		{ id: 'Past6Months', label: 'Past 6 Months', format: dateFormats.SHORT_MONTH_NAME_FORMAT, legendFormat: dateFormats.SHORT_MONTH_NAME_FORMAT, title: 'By Day', tag: '' },
		{ id: 'YearToDate', label: 'Year To Date', format: dateFormats.SHORT_MONTH_NAME_FORMAT, legendFormat: dateFormats.SHORT_MONTH_NAME_FORMAT, title: 'By Day', tag: '' },
		{ id: 'PastYear', label: 'Past Full Year', format: dateFormats.SHORT_MONTH_NAME_FORMAT, legendFormat: dateFormats.SHORT_MONTH_NAME_FORMAT, title: 'By Day', tag: '' },
		{ id: 'Monthly', label: 'Monthly', format: dateFormats.MONTH_YEAR_FORMAT, legendFormat: dateFormats.SHORT_MONTH_YEAR_FORMAT, title: 'By Month', tag: 'Month of ' },
		{ id: 'Quarterly', label: 'Quarterly', format: dateFormats.QUARTER_YEAR_FORMAT, legendFormat: dateFormats.SHORT_QUARTER_YEAR_FORMAT, title: 'By Quarter', tag: 'Quarter ' },
		{ id: 'Yearly', label: 'Year Over Year', format: dateFormats.MONTH_NAME_FORMAT, legendFormat: dateFormats.MONTH_NAME_FORMAT, title: 'By Year', tag: '' },
	],

	TREND_AOI_RANGES: [
		{ id: 'AllAOI', label: 'All AOI' },
		{ id: 'PartialAOI', label: 'Partial AOI' },
	],

	// water score categories
	WATER_SCORES: [
		{ label: 'Excellent', colour: '#00b050', threshold: 90 },
		{ label: 'Very Good', colour: '#92d050', threshold: 80 },
		{ label: 'Good', colour: '#ffff00', threshold: 75 },
		{ label: 'Slightly Compromised', colour: '#ffc000', threshold: 70 },
		{ label: 'Compromised', colour: '#ff0000', threshold: 65 },
		{ label: 'Highly Compromised', colour: '#c00000', threshold: 55 },
		{ label: 'Poor', colour: '#7030a0', threshold: 0 },
	],

	// water component categories

	WATER_COMPONENTS: [
		{
			label: 'Clouds',
			colour: '#fff',
		},
		{
			label: 'SML',
			colour: '#e9d568',
		},
		{
			label: 'SS',
			colour: '#cbc1c7',
		},
		{
			label: 'CDOM',
			colour: '#7a806c',
		},
		{
			label: 'Bloom',
			colour: '#369e97',
		},
	],

	BGI_CLASSIFICATION: {
		copernicus: [
			'NaN or Land',
			'Thick Clouds',
			'Thin Clouds',
			'Surface Micro Layer',
			'Suspended Sediments',
			'Oligotrophic Water',
			'Clear Water',
			'Mesotrophic Water (Bloom Negative)',
			'Mesotrophic Water (Bloom Positive)',
			'Coloured Dissolved Organic Matter',
			'Coloured Dissolved Organic Matter',
			'Coloured Dissolved Organic Matter',
			'Coloured Dissolved Organic Matter',
			'Coloured Dissolved Organic Matter',
			'Mesotrophic Water (Bloom Positive)',
			'Bloom Class 15',
			'Bloom Class 16',
			'Land',
			'Unclassified',
		],
		planet: ['NaN or Land', 'Thick Clouds', '--', '--', 'Suspended Sediments', '--', 'Oligotrophic Water', '--', 'Mesotrophic Water', '', 'Bloom', 'Unclassified (11)'],
	},
};

//modals
export const EXPORT_MODAL = 'EXPORT_MODAL';
export const ERROR_MODAL = 'ERROR_MODAL';

//notifications
export const MODAL_DISPLAY_TIME = 6000;

//LAYERS

export const TRUECOLOR = 'trueColour';
export const BLOOM = 'bloom';
export const CLOUDS = 'clouds';
export const INTERFERENCES = 'bgi';

export const PLANET_TRUE_COLOR = 'planetTrueColour';
export const PLANET_BGI = 'planetBGI';
export const PLANET_CLOUDS = 'planetClouds';
export const PLANET_BLOOM = 'planetBloom';

export const PRO_USER_DEFAULT_LAYERS = [CLOUDS, INTERFERENCES, BLOOM];

export const PRO_USER_LAYERS = [TRUECOLOR, BLOOM, CLOUDS, INTERFERENCES]; //interferences = ddifferent color for the sum of SML+CDOM+SS

export const PRO_USER_LAYERS_MENU = [
	{
		title: 'True color',
		checked: false,
		value: TRUECOLOR,
	},
	{
		title: 'Bloom',
		checked: false,
		value: BLOOM,
	},
	{
		title: 'Clouds',
		checked: false,
		value: CLOUDS,
	},
	{
		title: 'Interferences',
		checked: false,
		value: INTERFERENCES,
	},
	// {
	// 	title: 'In-situ sensors',
	// 	checked: false,
	// 	value: INTERFERENCES,
	// },
];

// export const STANDARD_USER_DEFAULT_LAYERS = [BLOOM, CLOUDS];//original
export const STANDARD_USER_DEFAULT_LAYERS = [CLOUDS, BLOOM, PLANET_TRUE_COLOR]; // delete later

export const STANDARD_USER_LAYERS = [TRUECOLOR, BLOOM, CLOUDS, INTERFERENCES]; //interferences = one color for the sum of SML+CDOM+SS

export const STANDARD_USER_LAYERS_MENU = [
	{
		title: 'True color',
		checked: false,
		value: TRUECOLOR,
	},
	{
		title: 'Bloom',
		checked: false,
		value: BLOOM,
	},
	{
		title: 'Clouds',
		checked: false,
		value: CLOUDS,
	},
	{
		title: 'Interferences',
		checked: false,
		value: INTERFERENCES,
	},
	// {
	// 	title: 'In-situ sensors',
	// 	checked: false,
	// 	value: INTERFERENCES,
	// },
];

export const SIMPLE_USER_PREFERNCES_LAYER_COLOR_HEX = '#cbc1c7';

export const getLegends = (isPro) => {
	return isPro ? legendsTemplate : legendsTemplate;
};

//SATELLITE VIEW

export const SENTINEL3 = 'Sentinel 3 (300m)';
export const PLANET_MENU_VALUE = 'Planet (3m)';

export const SATELLITES = {
	copernicus: 'copernicus',
	planet: 'planet',
};

//Thumbnails
export const THUMBNAILS = {
	copernicus: ['trueColour', 'bgi', 'bloom'],
	planet: ['trueColour', 'bgi', 'bloom'],
};

export const AOI_MAXIMUM_LOAD_TIME = 15000; //milliseconds
export const AOI_LOAD_TIME_EXCEED = 'AOI_LOAD_TIME_EXCEED'; //milliseconds
export const AOI_LOAD_TIME_VALID = 'AOI_LOAD_TIME_VALID'; //milliseconds

export const TRENDS_WATER_COMPONENTS_TAB = 'TRENDS_WATER_COMPONENTS_TAB';
export const TRENDS_ALERTS_TAB = 'TRENDS_ALERTS_TAB';
