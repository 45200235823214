import React, { useEffect, useRef, useState } from 'react';
import './index.css';
import BodySection from './components/bodySection/BodySection';
import BottomSection from './components/bottomSection/BottomSection';
import MainSection from './components/mainSection/MainSection';
import { MenuItem, Select, Typography } from '@mui/material';
import { Constants } from '../Constants';
import { getBloomPredictedData, getValues } from '../../services/trendsServices';
import { differenceInCalendarYears, format } from 'date-fns';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LakeInfo from './components/lakeInfo/LakeInfo';
import WaterComponents from './components/waterComponents/WaterComponents';
import dayjs from 'dayjs';
// import { getSatelliteParamName } from '../../services/mapboxServices';
// import { Tab, Tabs } from '@mui/material';
// import AlertsList from './components/alertsList/AlertsList';
// import AlertsFilters from './components/alertsFilters/AlertsFilters';

export default function MainTrends(props) {
	const trendsTitle = 'Water body trends';
	// const alertsTitle = 'Alerts';
	const defaultTrendType = 'BloomLevel';
	const defaultWeatherType = 'Temp';
	const { selectedDate, isTablet, location, user, collapseTrends, userMode, satelliteView, trendDataForAllSatellites } = props.data;
	// const alertsNumber = 3;

	let context = props.data;
	let trendData = [];
	let [dateRange, setDateRangeState] = useState(Constants.TREND_DATE_RANGES.find((_dateRange) => _dateRange.id === 'PastWeek'));
	// const [selectedTab, setSelectedTab] = useState(TRENDS_WATER_COMPONENTS_TAB);

	let [trendType, setTrendType] = useState(defaultTrendType);

	let [weatherType, setWeatherType] = useState(defaultWeatherType);

	let [dateRanges, setDateRangesState] = useState(Constants.TREND_DATE_RANGES);

	const [headerData, setHeaderData] = useState(null);

	if (context.trendData) {
		trendData = trendDataForAllSatellites ? trendDataForAllSatellites[satelliteView] : [];
	}
	const bloomPredictedData = getBloomPredictedData(trendData);

	let period = getValues([...trendData], dateRange, context);
	// let predictedPeriod = getValues([...trendData, ...bloomPredictedData], dateRange, context);

	const previousDate = useRef(null);

	useEffect(() => {
		let modifiedDate = format(selectedDate['$d'], Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
		modifiedDate = dayjs(modifiedDate);
		modifiedDate = modifiedDate['$d'];

		if (trendData && trendData.length > 0 && modifiedDate && previousDate.current !== modifiedDate) {
			let formattedDate = format(modifiedDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
			let index = trendData.findIndex((data, index) => {
				try {
					if (data && data.date) {
						let formatGivenDate = format(data.date, Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
						return formatGivenDate === formattedDate;
					}
				} catch (e) {}
			});

			previousDate.current = modifiedDate['$d'];
			setHeaderData(trendData[index]); // header data should get its data from imagery[satelliteView].trendData
		}
	}, [selectedDate]);
	// let imagery;
	// let satelliteKey = getSatelliteParamName(satteLiteView)
	// if(images && Object.keys(images) && Object.keys(images).length > 0 ){
	// 	let formatSelectedDate = format(selectedDate['$d'], Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
	// 	imagery = images[formatSelectedDate].imagery;
	// 	if(imagery && imagery[satelliteKey] && imagery[satelliteKey].trendData ){
	// 		setHeaderData(imagery[satelliteKey].trendData);// header data should get its data from imagery[satelliteView].trendData
	// 	}
	// }

	function toFixed(x, key) {
		return Number.parseFloat(x).toFixed(Constants.UNITS[context.user.units][key].decimals);
	}

	const bloomSpreadAverage = period.averages['BloomSpread'];
	const bloomLevelAverage = period.averages['BloomLevel'];
	const bloomSpreadAnnotation = period.averages[`BloomSpreadAnnotation`];
	const bloomLevelAnnotation = period.averages[`BloomLevelAnnotation`];

	const carbonAverage = period.averages['Carbon'];
	const carbonAnnotation = period.averages[`CarbonAnnotation`];

	let bloomLevelDelta = period.averages[`BloomLevelDelta`];
	let bloomLevelDirection = '';
	let bloomSpreadDelta = period.averages[`BloomSpreadDelta`];
	let bloomSpreadDirection = '';

	let carbonDelta = period.averages[`CarbonDelta`];
	let carbonDirection = '';

	const weatherValues = headerData ? headerData.weather[headerData.weatherKeys[0]] : '';
	let tempratureMin = null;
	let tempratureMax = null;

	if (headerData && weatherValues && weatherValues.temperature_2m_min) {
		tempratureMin = weatherValues.temperature_2m_min;
	}
	if (headerData && weatherValues && weatherValues.temperature_2m_max) {
		tempratureMax = weatherValues.temperature_2m_max;
	}

	const showAverageRange = tempratureMin !== undefined && tempratureMin !== null && tempratureMax !== undefined && tempratureMax !== null;

	const mainSectionProps = {
		windSpeed: headerData ? weatherValues?.windspeed_10m : '',
		bloomLevel: headerData ? toFixed(headerData?.BloomLevel, 'BloomLevel') : '',
		bloomSpread: headerData ? toFixed(headerData?.BloomSpread, 'BloomSpread') : '',
		selectedDate: selectedDate,
		user: context.user,
		bloomLevelAnnotation: bloomLevelAnnotation,
		wc: headerData && weatherValues ? weatherValues?.weathercode : 0,
		windDirection: headerData && weatherValues ? headerData && weatherValues?.winddirection_10m : '',
		precip: headerData && weatherValues ? headerData && weatherValues?.precipitation : '',
		isTablet: isTablet,
		showAverageRange,
	};

	if (bloomLevelDelta && bloomLevelDelta.includes(Constants.UP)) {
		bloomLevelDelta = bloomLevelDelta.replace(Constants.UP, '');
		bloomLevelDirection = 'up';
	}
	if (bloomLevelDelta && bloomLevelDelta.includes(Constants.DOWN)) {
		bloomLevelDelta = bloomLevelDelta.replace(Constants.DOWN, '');
		bloomLevelDirection = 'down';
	}
	if (bloomSpreadDelta && bloomSpreadDelta.includes(Constants.UP)) {
		bloomSpreadDelta = bloomSpreadDelta.replace(Constants.UP, '');
		bloomSpreadDirection = 'up';
	}
	if (bloomSpreadDelta && bloomSpreadDelta.includes(Constants.DOWN)) {
		bloomSpreadDelta = bloomSpreadDelta.replace(Constants.DOWN, '');
		bloomSpreadDirection = 'down';
	}

	if (carbonDelta && carbonDelta.includes(Constants.UP)) {
		carbonDelta = carbonDelta.replace(Constants.UP, '');
		carbonDirection = 'up';
	}
	if (carbonDelta && carbonDelta.includes(Constants.DOWN)) {
		carbonDelta = carbonDelta.replace(Constants.DOWN, '');
		carbonDirection = 'down';
	}

	const bodyProps = { bloomSpreadAverage, bloomLevelAverage, carbonAverage, bloomSpreadAnnotation, bloomLevelAnnotation, carbonAnnotation, bloomLevelDelta, bloomSpreadDelta, bloomLevelDirection, bloomSpreadDirection, carbonDelta, carbonDirection, setTrendType, setDateRangeState, user: user };

	const bottomProps = {
		dateRange,
		current: period.current,
		trendType: trendType,
		period,
		weatherType,
		context,
		setWeatherType,
		trendData: trendData,
		dateRanges: dateRanges,
		isTablet: isTablet,
		bloomPredictedData: bloomPredictedData,
	};

	const setDateRange = (event) => {
		let value = dateRanges.find((_dateRange) => _dateRange.id === event.target.value);
		setDateRangeState(value);
	};

	if (dateRanges.length === Constants.TREND_DATE_RANGES.length) {
		let additionalRanges = [];
		let thisYear = new Date().getFullYear();

		for (let yearIndex = 1; yearIndex <= differenceInCalendarYears(trendData[trendData.length - 1].date, trendData[0].date); yearIndex++) {
			additionalRanges.push({
				id: (thisYear - yearIndex).toString(),
				label: (thisYear - yearIndex).toString(),
				format: Constants.DATE_FORMATS.MONTH_NAME_FORMAT,
				legendFormat: Constants.DATE_FORMATS.MONTH_NAME_FORMAT,
				title: 'By Week',
				tag: 'Week of ',
			});
		}

		if (trendData[0].date.getFullYear() === trendData[trendData.length - 1].date.getFullYear()) {
			dateRanges.splice(-1);
		}

		let newValue = [...dateRanges, ...additionalRanges];
		if (newValue.length !== dateRanges.length) {
			setDateRangesState(newValue);
		}
	}

	const lakeInfoProps = {
		location: location,
		user: user,
	};

	let mainContainerClassName = 'trends-container';
	if (isTablet) {
		mainContainerClassName = mainContainerClassName + ' tablet';
	}
	if (collapseTrends) {
		mainContainerClassName = mainContainerClassName + ' collapsed';
	}

	const waterComponentsProps = {
		period: period,
		dateRange: dateRange,
		satelliteView: satelliteView, //original
	};

	// const getTrendsTabContent = () => {
	// 	return (
	// 		<>
	// 			{/* <Typography className="trends-title" style={{ color: '#000000', opacity: '0.75', letterSpacing: '-0.28px' }}>
	// 				{trendsTitle}
	// 			</Typography> */}

	// 			<div style={{ padding: '9px 8px 13px 16px', height: '41px', display: 'flex' }}>
	// 				<Select value={dateRange.id} className="select-period" onChange={setDateRange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
	// 					{dateRanges.map((_dateRange) => (
	// 						<MenuItem key={'c' + Math.floor(Math.random() * 99999999999) + _dateRange.id} value={_dateRange.id}>
	// 							<AccessTimeIcon style={{ height: '16px', width: '16px' }} fontSize="small" /> <span style={{ marginLeft: '6px' }}> {_dateRange.label}</span>
	// 						</MenuItem>
	// 					))}
	// 				</Select>
	// 			</div>

	// 			{<BodySection {...bodyProps} />}
	// 			{<BottomSection {...bottomProps} />}
	// 			{isTablet ? '' : userMode === 'expert' ? <WaterComponents {...waterComponentsProps} /> : ''}
	// 			<hr style={{ margin: '0' }} />

	// 			<LakeInfo {...lakeInfoProps} />
	// 		</>
	// 	);
	// };

	// const alerts = [
	// 	{
	// 		type: 'danger',
	// 		title: 'HAB spot located in zone B Of Sorek destination port',
	// 		distance: '8700',
	// 		date: new Date(),
	// 	},
	// 	{
	// 		type: 'warning',
	// 		title: 'HAB spot located in zone B',
	// 		distance: '5000',
	// 		date: new Date(),
	// 	},
	// 	{
	// 		type: 'ok',
	// 		title: 'HAB spot located in zone B',
	// 		distance: '3000',
	// 		date: new Date(),
	// 	},
	// ];

	// const getAlertsTabContent = () => {
	// 	return (
	// 		<>
	// 			{' '}
	// 			{/* <AlertsFilters dateRanges={dateRanges} dateRange={dateRange} setDateRange={setDateRange} /> */}
	// 			<AlertsList alerts={alerts} />{' '}
	// 		</>
	// 	);
	// };

	// const renderTabContent = () => {
	// 	if (selectedTab === TRENDS_WATER_COMPONENTS_TAB) {
	// 		return getTrendsTabContent();
	// 	} else if (selectedTab === TRENDS_ALERTS_TAB) {
	// 		return getAlertsTabContent();
	// 	}
	// };

	// const handleChange = (event, newValue) => {
	// 	setSelectedTab(newValue);
	// };

	// const alertsLabelComponent = (
	// 	<span style={{ display: 'flex' }}>
	// 		<span style={{ marginRight: '5px' }}>{alertsTitle} </span>
	// 		<span className="alerts-number">{`${alertsNumber}`}</span>
	// 	</span>
	// );

	return (
		<div className={mainContainerClassName}>
			<MainSection {...mainSectionProps} tempratureMin={tempratureMin} tempratureMax={showAverageRange ? tempratureMax : weatherValues.temperature_2m} />

			{/* <div style={{ padding: '14px 0px', boxSizing: 'content-box' }}>
				<Tabs value={selectedTab} onChange={handleChange} aria-label="" className="tabs-container">
					<Tab value={TRENDS_WATER_COMPONENTS_TAB} label={trendsTitle} className="tab-button" />
					<Tab value={TRENDS_ALERTS_TAB} label={alertsLabelComponent} wrapped className="tab-button" />
				</Tabs>
			</div> */}

			{/* {renderTabContent()} */}

			<Typography className="trends-title" style={{ color: '#000000', opacity: '0.75', letterSpacing: '-0.28px' }}>
				{trendsTitle}
			</Typography>

			<div style={{ padding: '9px 8px 13px 16px', height: '41px', display: 'flex' }}>
				<Select value={dateRange.id} className="select-period" onChange={setDateRange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
					{dateRanges.map((_dateRange) => (
						<MenuItem key={'c' + Math.floor(Math.random() * 99999999999) + _dateRange.id} value={_dateRange.id}>
							<AccessTimeIcon style={{ height: '16px', width: '16px' }} fontSize="small" /> <span style={{ marginLeft: '6px' }}> {_dateRange.label}</span>
						</MenuItem>
					))}
				</Select>
			</div>

			{<BodySection {...bodyProps} />}
			{<BottomSection {...bottomProps} />}
			{isTablet ? '' : userMode === 'expert' ? <WaterComponents {...waterComponentsProps} /> : ''}
			<hr style={{ margin: '0' }} />

			<LakeInfo {...lakeInfoProps} />
		</div>
	);
}
