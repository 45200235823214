import React from 'react';
import './index.css';
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
// import DownloadIcon from '@mui/icons-material/Download';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function DownloadMenu({ imagery, selectedLocation, satelliteView, }) {
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef(null);

	const s3AOI = 'S3 AOI';
	const planet = 'Planet';
	const rayleigh = 'Rayleigh';

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	function handleListKeyDown(event) {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		} else if (event.key === 'Escape') {
			setOpen(false);
		}
	}

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = React.useRef(open);
	React.useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);

	const onLinkClick = (event, url) => {
		if (!url || !window) return;

		// if context.imagery.urls.planet_zip

		handleClose(event);
		window.open(url);
	};

	let s3Link = '';
	let rayleighLink = '';
	let planetLink = '';
	if (imagery && imagery.urls) {
		if (imagery.urls.Subsetted300m_zip_url) {
			s3Link = imagery.urls.Subsetted300m_zip_url;
		}
		if (imagery.urls.Rayleigh300m_zip_url) {
			rayleighLink = imagery.urls.Rayleigh300m_zip_url;
		}
		if (imagery.urls.Subsetted300m_zip_url) {
			planetLink = imagery[satelliteView].urls.planet_zip;
		}
	}

	// let menuDisabled = !s3Link && !rayleighLink && !planetLink;
	let menuDisabled = selectedLocation.name ? false : true;
	// let menuDisabled = false;

	return (
		<div className="download-menu-container">
			<Button disableRipple color='primary' className="download-button" ref={anchorRef} disabled={menuDisabled} id="composition-button" aria-controls={open ? 'composition-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={handleToggle} endIcon={<ArrowDropDownIcon />}>
				<DownloadOutlinedIcon fontSize={'small'} />
			</Button>
			<Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-end" transition disablePortal style={{ width: '282px' }}>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
						}}>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button" onKeyDown={handleListKeyDown} transform-origin={{ horizontal: 'right', vertical: 'top' }} anchor-origin={{ horizontal: 'right', vertical: 'bottom' }}>
									<MenuItem
										onClick={(event) => {
											onLinkClick(event, s3Link);
										}}
										disabled={!s3Link}>
										{s3AOI}
									</MenuItem>
									<MenuItem
										onClick={(event) => {
											onLinkClick(event, rayleighLink);
										}}
										disabled={!rayleighLink}>
										{rayleigh}
									</MenuItem>
									<MenuItem
										onClick={(event) => {
											onLinkClick(event, planetLink);
										}}
										disabled={!planetLink}>
										{planet}
									</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	);
}
