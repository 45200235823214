import React from 'react';
import './index.css';
import { createContainer, VictoryChart, VictoryAxis, VictoryLabel, VictoryStack, VictoryLegend, VictoryTooltip, VictoryBar, LineSegment } from 'victory';
import { adjustForDataGaps } from '../../../../services/trendsServices';
import { format as formatDate } from 'date-fns';
import { Constants, SATELLITES } from '../../../Constants';
import { victoryLGVTheme } from '../../../victoryLGVTheme';
import { isBlank } from '../../../Services';

export default function WaterComponents({ period, dateRange, satelliteView }) {
	let heightFactor = window.matchMedia('(min-height: 913px) and (max-height: 1079px)').matches ? 0.9 : 1;
	const victoryBar = (waterComponent, data) => {
		let response = <></>,
			actualWaterComponent = waterComponent === 'Bloom' ? 'BloomSpread' : waterComponent,
			// legendItem = Constants.WATER_COMPONENTS.find((_legend) => _legend.id === waterComponent), // this must be waterComponent, not actualWaterComponent to get the correct legendItem
			legendItem = Constants.WATER_COMPONENTS.find((_legend) => _legend.label === waterComponent), // this must be waterComponent, not actualWaterComponent to get the correct legendItem
			labels = (datum, label) => {
				return [`${dateRange.tag}${formatDate(datum.date ? datum.date : datum.x ? datum.x : new Date(), dateRange.legendFormat)}`, label, `${(datum[actualWaterComponent] ? datum[actualWaterComponent] : datum.y ? datum.y : 0).toFixed(1)}%`, `${datum.estimated ? 'Estimated' : ''}`];
			},
			_labelComponent = labelComponent(2, !isBlank(dateRange.tag)),
			dataStyle = {
				data: {
					stroke: 'rgba(0, 0, 0, 0.25)',
					strokeWidth: 1,
					fill: legendItem.colour,
				},
				parent: {
					border: '1px solid #ccc',
					height: `${190 * heightFactor}px`,
					width: '600px',
				},
			},
			y = actualWaterComponent;
		// y = waterComponent;

		response = data.map((_data, _index) => {
			return <VictoryBar style={dataStyle} key={'b' + _index} data={_data} x="date" y={y} labels={({ datum }) => labels(datum, legendItem.label)} labelComponent={_labelComponent} />;
		});

		return response;
	};

	const labelComponent = (lines, oversize) => {
		// let width = oversize ? 300 : 200,
		let dx = 0,
			style = [{ fontSize: 16, fontWeight: 'bold' }];

		for (let line = 0; line <= lines; line++) {
			style.push({
				fontSize: line === 1 ? 28 : line === 0 ? 18 : 16,
				fontWeight: line === 1 || line === 0 ? 'bold' : 'normal',
			});
		}

		return (
			<VictoryTooltip
				constrainToVisibleArea
				// horizontal
				cornerRadius={5}
				flyoutStyle={{
					// fill: 'rgb(229, 229, 230)',
					fill: 'white',
				}}
				flyoutWidth={132}
				flyoutHeight={(lines + 1) * 25 + 10}
				floyoutPadding={5}
				labelComponent={<VictoryLabel style={style} dx={dx} />}
			/>
		);
	};

	const adjustedData = adjustForDataGaps('waterComponents', true, period.current);

	const viewWaterComponents = () => {
		const VictoryZoomVoronoiContainer = createContainer('zoom', 'voronoi');

		let containerComponent = (
				<VictoryZoomVoronoiContainer
					responsive={true}
					zoomDimension="x"
					//zoomDomain={   state.zoomDomain} // needed for brush interaction
					//onZoomDomainChange={   handleZoom.bind(this)} // needed for brush interaction
				/>
			),
			data = [adjustedData],
			wcData =
				satelliteView === SATELLITES.planet
					? [
							{
								name: 'Clouds',
								symbol: {
									stroke: '#000',
									strokeWidth: 1,
									// fill: Constants.LEGENDS[satelliteView].bgi.find((_legend) => _legend.label === 'Clouds').colour,
									fill: Constants.WATER_COMPONENTS.find((_legend) => _legend.label === 'Clouds').colour,
									type: 'diamond',
								},
								x: 50,
							},
							{
								name: 'SS',
								symbol: {
									// fill: Constants.LEGENDS[satelliteView].bgi.find((_legend) => _legend.id === 'SS').colour,
									fill: Constants.WATER_COMPONENTS.find((_legend) => _legend.label === 'SS').colour,
									type: 'diamond',
								},
							},
							{
								name: 'Bloom Level',
								symbol: {
									// fill: Constants.LEGENDS[satelliteView].bgi.find((_legend) => _legend.id === 'BloomLevel').colour,
									fill: Constants.WATER_COMPONENTS.find((_legend) => _legend.label === 'Bloom').colour,
									type: 'diamond',
								},
							},
					  ]
					: [
							{
								name: 'Clouds',
								symbol: {
									stroke: '#000',
									strokeWidth: 1,
									// fill: Constants.LEGENDS[satelliteView].bgi.find((_legend) => _legend.label === 'Clouds').colour,
									fill: Constants.WATER_COMPONENTS.find((_legend) => _legend.label === 'Clouds').colour,
									type: 'diamond',
								},
								x: 50,
							},
							{
								name: 'SS',
								symbol: {
									// fill: Constants.LEGENDS[satelliteView].bgi.find((_legend) => _legend.id === 'SS').colour,
									fill: Constants.WATER_COMPONENTS.find((_legend) => _legend.label === 'SS').colour,
									type: 'diamond',
								},
							},
							{
								name: 'SML',
								symbol: {
									// fill: Constants.LEGENDS[satelliteView].bgi.find((_legend) => _legend.id === 'SML').colour,
									fill: Constants.WATER_COMPONENTS.find((_legend) => _legend.label === 'SML').colour,
									type: 'diamond',
								},
							},
							{
								name: 'CDOM',
								symbol: {
									// fill: Constants.LEGENDS[satelliteView].bgi.find((_legend) => _legend.id === 'CDOM').colour,
									fill: Constants.WATER_COMPONENTS.find((_legend) => _legend.label === 'CDOM').colour,
									type: 'diamond',
								},
							},
							{
								name: 'Bloom Level',
								symbol: {
									// fill: Constants.LEGENDS[satelliteView].bgi.find((_legend) => _legend.id === 'BloomLevel').colour,
									fill: Constants.WATER_COMPONENTS.find((_legend) => _legend.label === 'Bloom').colour,
									type: 'diamond',
								},
							},
					  ],
			response = [
				<React.Fragment key="WaterComponents">
					<VictoryChart theme={victoryLGVTheme} preserveAspectRatio="none" width={600} height={190 * heightFactor} domainPadding={10} padding={{ left: 70, right: 25, top: 50, bottom: 0 }} containerComponent={containerComponent}>
						<VictoryAxis
							dependentAxis
							fixLabelOverlap={true}
							tickFormat={(datum) => `${datum}%`}
							style={{
								axis: { stroke: { fill: 'none' } },
								tickLabels: { fill: 'black', fontSize: 15 },
							}}
						/>
						<VictoryAxis
							orientation="bottom"
							fixLabelOverlap={true}
							tickValues={data[0].map((_datum) => _datum.date)}
							tickFormat={(datum) => `${dateRange.format.includes('Q') ? 'Q' : ''}${formatDate(new Date(datum), dateRange.format)}`}
							style={{
								ticks: { stroke: 'black', size: 5 },
								tickLabels: { fill: 'black', fontSize: 15 },
							}}
							gridComponent={<LineSegment style={{ stroke: 'none' }} />}
						/>
						<VictoryLegend orientation="horizontal" titleComponent={<VictoryLabel style={{ fontSize: 18, fontWeight: 'bold' }} />} labelComponent={<VictoryLabel style={{ fontSize: 16 }} />} gutter={40} data={wcData} />

						{satelliteView === SATELLITES.planet ? (
							<VictoryStack colorScale={Constants.WATER_COMPONENTS.map((_waterComponent) => _waterComponent.colour)}>
								{victoryBar('Clouds', data)}
								{victoryBar('SS', data)}
								{victoryBar('Bloom', data)}
							</VictoryStack>
						) : (
							<VictoryStack colorScale={Constants.WATER_COMPONENTS.map((_waterComponent) => _waterComponent.colour)}>
								{victoryBar('Clouds', data)}
								{victoryBar('SS', data)}
								{victoryBar('SML', data)}
								{victoryBar('CDOM', data)}
								{victoryBar('Bloom', data)}
							</VictoryStack>
						)}
					</VictoryChart>
				</React.Fragment>,
			];

		return response;
	};
	return (
		<div style={{ maxHeight: '225px' }} className={'water-components'}>
			<div style={{ height: '225px', paddingLeft: '16px' }}>
				<span className="water-components-title">Water Component</span>
				{viewWaterComponents()}
			</div>
		</div>
	);
}
